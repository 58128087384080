import './App.css';
import Home from './pages/Home'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import React from 'react'

class App extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      connMobile : window.innerWidth>600?false:true
    }
  }

  handleWindowsize = () =>{
      window.innerWidth>600?this.state.connMobile===false?console.log('handle size PC'):this.setState({connMobile : false}):this.state.connMobile===true?console.log('handle size Mobile'):this.setState({connMobile : true});
  }

  componentDidMount(){
    window.addEventListener("resize", this.handleWindowsize);
  }

  componentWillUnmount(){
    window.removeEventListener("resize", this.handleWindowsize);
  }

  render() {
    return (
      <div className="App">
      <header style={{paddingBottom:'20px'}}>
        <img src='assets/logo/logo.png' alt="logo" className="logoHeader" /><br/>
        <span style={{color:'white',fontWeight:'600',fontSize:'18px'}}>ลูกชิ้นปลามหาชัย.com</span>
      </header>
      <Router>
        <Switch>
          <Route path="/">
            <Home connMobile={this.state.connMobile} nextLoad={this.state.nextLoad} />
          </Route>
        </Switch>
      </Router>
      <footer style={{paddingTop:'5px', paddingBottom:'5px'}}>
        <span style={{color:'white'}}>ลูกชิ้นปลามหาชัย.com สดใหม่ ส่งไว บริการประทับใจ</span>
      </footer>

      <div className="mobileMenu">
        <a href="tel:0969462289" rel="nofollow noreferrer" target="_blank"><div style={{width:'50%',float:'left'}}><img src="assets/etc/tel-icon.png" alt="telephone" className="lineImg" width="30px"/><br/>โทรหาเรา</div></a>
        <a href="https://lin.ee/Ya2p1qW" rel="nofollow noreferrer" target="_blank"><div style={{width:'50%',float:'right'}}><img src="assets/etc/line-icon.png" alt="line-id" className="lineImg" width="30px"/><br/>ซื้อสินค้าทางไลน์</div></a>
      </div>
    </div>
    )
  }
}

export default App;
