import React from 'react'
import {Link} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet, HelmetProvider } from 'react-helmet-async';

class Homepage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            connMobile : this.props.connMobile,
            nextLoad : false
          }
    }

    componentDidMount(){
        window.addEventListener("scroll", this.handleWindowscroll);
    }

    componentDidUpdate(){
        //console.log(this.props.connMobile)
    }

    componentWillUnmount(){
        window.removeEventListener("scroll", this.handleWindowscroll); 
    }

    handleWindowscroll = () =>{
        this.state.connMobile===true?window.scrollY>200?this.state.nextLoad===true?console.log('handle scroll M >300'):this.setState({nextLoad : true}):this.state.nextLoad===false?console.log('handle scroll M <= 200'):this.setState({nextLoad : false}):console.log('handle scroll PC');
    }

    productHot = [
        /* ไก่จ๊า 500 กรัม = 714/6 โล
        /* 30% */ {name:'ปลาระเบิด(แหลม) SP',priceAgent: '47 บาท/กก.',price: '58 บาท/กก.',img:'ปลาระเบิดแหลม-sp.jpg',size:'1 กก.',priceToon:'40'},
        /* 30% */ {name:'หมูยออุบล',priceAgent: '29 บาท/แพ็ค',price: '30 บาท/แพ็ค',img:'หมูยออุบล.jpg',size:'400 กรัม',priceToon:'20'},
        /* 30% */ {name:'ลูกชิ้นปลา(แหลม) เกรดพรีเมี่ยม',priceAgent: '115 บาท/กก.',price: '145 บาท/กก.',img:'ลูกชิ้นปลาแหลม-เกรดพรีเมี่ยม-บู๊ฮวด.jpg',size:'1 กก.',priceToon:'98'},
        /* 30% */ {name:'ลูกชิ้นปลากราย(แบน) ส มหาชัย',priceAgent: '61 บาท/กก.',price: '65 บาท/กก.',img:'ลูกชิ้นปลาฟ้า-ส-มหาชัย-แบน.jpg',size:'500 กรัม, 1 กก.',priceToon:'47'},
        /* 30% */ {name:'น้ำจิ้มมะขาม(ขวดเล็ก) จอมยุทธ์16',priceAgent: '9 บาท/ขวด.',price: '15 บาท/ขวด',img:'น้ำจิ้มมะขาม-จอมยุทธ์-ขวดเล็ก.webp',size:'220 กรัม',priceToon:'7.5'}, // 4 ขวดโล
        /* 30% */ {name:'เกี๊ยวปลา',priceAgent: '155 บาท/กก.',price: '169 บาท/กก.',img:'เกี๊ยวปลา.jpg',size:'1 กก.',priceToon:'125'},
        /* 30% */ {name:'ลูกชิ้นหมูเกรด A หมูยิ้ม กลมใหญ่',priceAgent: '72 บาท/กก',price: '79 บาท/กก',img:'ลูกชิ้นหมูยิ้มเกรด-a-กลมใหญ่.jpg',size:'1 กก',priceToon:'47'},
        /* 30% */ {name:'ลูกชิ้นปลา(แบน) นายเฮี้ยง',priceAgent: '69 บาท/กก.',price: '79 บาท/กก.',img:'ลูกชิ้นปลา-บุ๋ม-นายเฮี้ยง.jpg',size:'500 กรัม, 1 กก.',priceToon:'58'},
        /* 30% */ {name:'ไส้กรอกหนังกรอบ(หมูผสมไก่) JPM จัมโบ้',priceAgent: '80 บาท/กก.',price: '85 บาท/กก.',img:'ไส้กรอก-jpm-จัมโบ้.jpg',size:'1 กก.',priceToon:'60'},
        /* 30% */ {name:'ลูกชิ้นปลาทอด(กลม) 5 ดาว',priceAgent: '39 บาท/กก.',price: '49 บาท/กก.',img:'ปลาระเบิดกลม-5-ดาว.jpg',size:'1 กก.',priceToon:'30'},
    ]

    product = [
        /* ไก่จ๊า 500 กรัม = 714/6 โล
        /* 30% */ {name:'ปลาระเบิด(กลม) SP',priceAgent: '47 บาท/กก.',price: '58 บาท/กก.',img:'ปลาระเบิดกลม-sp.jpg',size:'1 กก.',priceToon:'40'},
        /* 30% */ {name:'ปลาระเบิด(แหลม) SP',priceAgent: '47 บาท/กก.',price: '58 บาท/กก.',img:'ปลาระเบิดแหลม-sp.jpg',size:'1 กก.',priceToon:'40'},
        /* 30% */ {name:'ปลาเส้น SP',priceAgent: '19 บาท/เส้น',price: '28  บาท/เส้น',img:'ปลาเส้น-sp.jpg',size:'160 กรัม',priceToon:'15'}, // 5 เส้น = ประมาณ 1 กก.
        /* 30% */ {name:'วิวปลา นายไสว',priceAgent: '68 บาท/กก.',price: '78  บาท/กก.',img:'วิวปลานายไสว.jpg',size:'1 กก.',priceToon:'58'},
        /* 30% */ {name:'ไส้กรอกอีสานหมู',priceAgent: '76 บาท/กก.',price: '90  บาท/กก.',img:'ไส้กรอกอีสานหมู.jpg',size:'1 กก.',priceToon:'70'},
        /* 30% */ {name:'ปลาเส้นบู๊ฮวด (แพ็ค 5 เส้น)',priceAgent: '105 บาท/แพ็ค',price: '125  บาท/แพ็ค',img:'ปลาเส้นบู๊ฮวด.jpg',size:'800 กรัม',priceToon:'90'},
        {name:'ปลาเส้นต้ม บู๊ฮวด (แพ็ค 5 เส้น)',priceAgent: '105/แพ็ค',price: '125/แพ็ค.',img:'ปลาเส้นต้ม-บู๊ฮวด.jpg',size:'800 กรัม',priceToon:'90'},
        /* 30% */ {name:'หมูกระเทียม กาญจนา',priceAgent: '33 บาท/แพ็ค',price: '40 บาท/แพ็ค',img:'หมูกระเทียม-กาญจนา.jpg',size:'400 กรัม',priceToon:'25'},  // 2 ถุง = ประมาณ 1 กก.
        /* 30% */ {name:'ปลาระเบิด(กลม) 5 ดาว',priceAgent: '49 บาท/กก.',price: '58 บาท/กก.',img:'ปลาระเบิดกลม-โรงงาน5ดาว.jpg',size:'1 กก.',priceToon:'43'},
        /* 30% */ {name:'ปลาระเบิด(แหลม) 5 ดาว',priceAgent: '49 บาท/กก.',price: '58 บาท/กก.',img:'ปลาระเบิด-แหลม-5-ดาว.jpg',size:'1 กก.',priceToon:'43'},
        /* 30% */ {name:'ลูกชิ้นปลาทอด(กลม) 5 ดาว',priceAgent: '39 บาท/กก.',price: '49 บาท/กก.',img:'ปลาระเบิดกลม-5-ดาว.jpg',size:'1 กก.',priceToon:'33'},
        /* 30% */ {name:'ลูกชิ้นปลาทอด(แหลม) 5 ดาว',priceAgent: '39 บาท/กก.',price: '49 บาท/กก.',img:'ปลาระเบิดแหลม-5-ดาว.jpg',size:'1 กก.',priceToon:'33'},
        /* 30% */ {name:'ลูกชิ้นปลาทอดแดง(แบน) 5 ดาว',priceAgent: '39 บาท/กก.',price: '49 บาท/กก.',img:'ปลาแดงแบน-5-ดาว.jpg',size:'1 กก.',priceToon:'33'},
        /* 30% */ {name:'ลูกชิ้นปลาทอดแดง(กลม) 5 ดาว',priceAgent: '39 บาท/กก.',price: '49 บาท/กก.',img:'ปลาแดงกลม-5-ดาว.jpg',size:'1 กก.',priceToon:'33'},
        /* 30%  */{name:'ปลาแผ่น 5 ดาว',priceAgent: '55 บาท/กก.',price: '60 บาท/กก.',img:'เส้นตัดปลา-5-ดาว.jpg',size:'500 กรัม',priceToon:'50'},
        /* 30% */ {name:'ลูกชิ้นปลากราย(แหลม) ส มหาชัย',priceAgent: '61 บาท/กก.',price: '65 บาท/กก.',img:'ลูกชิ้นปลากรายแหลม-ส-มหาชัย.jpg',size:'500 กรัม, 1 กก.',priceToon:'47'},
        /* 30% */ {name:'ลูกชิ้นปลากราย(กลม) ส มหาชัย',priceAgent: '61 บาท/กก.',price: '65 บาท/กก.',img:'ลูกชิ้นปลากรายกลม-ส-มหาชัย.jpg',size:'500 กรัม, 1 กก.',priceToon:'47'},
        /* 30% */ {name:'ลูกชิ้นปลากราย(แบน) ส มหาชัย',priceAgent: '61 บาท/กก.',price: '65 บาท/กก.',img:'ลูกชิ้นปลาฟ้า-ส-มหาชัย-แบน.jpg',size:'500 กรัม, 1 กก.',priceToon:'47'},
        /* 30% */ {name:'ลูกชิ้นปลา A 5 ดาว(แหลมจัมโบ้)',priceAgent: '67 บาท/กก.',price: '80 บาท/กก.',img:'ลูกชิ้นปลาแหลม-5-ดาว.jpg',size:'500 กรัม, 1 กก.',priceToon:'51'},
        /* 30% */ {name:'ลูกชิ้นปลา(กลม) นายเฮี้ยง',priceAgent: '69 บาท/กก.',price: '79 บาท/กก.',img:'ลูกชิ้นปลา-กลม-นายเฮี้ยง.jpg',size:'500 กรัม, 1 กก.',priceToon:'58'},
        /* 30% */ {name:'ลูกชิ้นปลา(แหลม) นายเฮี้ยง',priceAgent: '69 บาท/กก.',price: '79 บาท/กก.',img:'ลูกชิ้นปลาแหลม-นายเฮี้ยง.jpg',size:'500 กรัม, 1 กก.',priceToon:'58'},
        /* 30% */ {name:'ลูกชิ้นปลา(แบน) นายเฮี้ยง',priceAgent: '69 บาท/กก.',price: '79 บาท/กก.',img:'ลูกชิ้นปลา-บุ๋ม-นายเฮี้ยง.jpg',size:'500 กรัม, 1 กก.',priceToon:'58'},
        /* 30% */ {name:'ลูกชิ้นปลาถุงเหลือง(แบน) บู๊ฮวด',priceAgent: '67 บาท/กก.',price: '75 บาท/กก.',img:'ลูกชิ้นปลาแบนเหลือง-บู๋ฮวด.jpg',size:'500 กรัม, 1 กก.',priceToon:'51'},
        /* 30% */ {name:'ลูกชิ้นปลาถุงเหลือง(กลม) บู๊ฮวด',priceAgent: '67 บาท/กก.',price: '75 บาท/กก.',img:'ลูกชิ้นปลาถุงเหลืองกลม-บู๊ฮวด.jpg',size:'500 กรัม, 1 กก.',priceToon:'51'},
        /* 30% */ {name:'ลูกชิ้นปลาถุงเหลือง(กลมเล็ก) บู๊ฮวด',priceAgent: '67 บาท/กก.',price: '75 บาท/กก.',img:'ลูกชิ้นปลากลมเล็ก-บู๊ฮวด.jpg',size:'500 กรัม, 1 กก.',priceToon:'51'},
        /* 30% */ {name:'ลูกชิ้นปลาถุงเหลือง(แหลม) บู๊ฮวด',priceAgent: '67 บาท/กก.',price: '75 บาท/กก.',img:'ลูกชิ้นปลาถุงเหลืองแหลม-บู๊ฮวด.jpg',size:'500 กรัม, 1 กก.',priceToon:'51'},
        /* 30% */ {name:'ลูกชิ้นปลาถุงน้ำเงิน(แหลม) บู๊ฮวด',priceAgent: '87 บาท/กก.',price: '90 บาท/กก.',img:'ลูกชิ้นปลาแหลม-บู๊ฮวดถุงน้ำเงิน.jpg',size:'500 กรัม, 1 กก.',priceToon:'71'},
        /* 30% */ {name:'ลูกชิ้นปลาถุงน้ำเงิน(กลม) บู๊ฮวด',priceAgent: '87 บาท/กก.',price: '90 บาท/กก.',img:'ลูกชิ้นปลากลม-บู๊ฮวดถุงน้ำเงิน.jpg',size:'500 กรัม, 1 กก.',priceToon:'71'},
        /* 30% */ {name:'ลูกชิ้นปลาถุงน้ำเงิน(แบน) บู๊ฮวด',priceAgent: '87 บาท/กก.',price: '90 บาท/กก.',img:'ลูกชิ้นปลาแบน-บู๊ฮวดถุงน้ำเงิน.jpg',size:'500 กรัม, 1 กก.',priceToon:'71'},
        /* 30% */ {name:'ลูกชิ้นปลา(กลม) 9 ดาว',priceAgent: '50 บาท/กก.',price: '59 บาท/กก.',img:'ลูกชิ้นปลากลมกลาง-9-ดาว.jpg',size:'500 กรัม, 1 กก.',priceToon:'40'},
        /* 30% */ {name:'ลูกชิ้นปลา(กลมใหญ่) 9 ดาว',priceAgent: '50 บาท/กก.',price: '59 บาท/กก.',img:'ลูกชิ้นปลากลมใหญ่-9-ดาว.jpg',size:'500 กรัม, 1 กก.',priceToon:'40'},
        /* 30% */ {name:'ลูกชิ้นปลา(แหลม) 9 ดาว',priceAgent: '50 บาท/กก.',price: '59 บาท/กก.',img:'ลูกชิ้นปลาแหลม-9-ดาว.jpg',size:'500 กรัม, 1 กก.',priceToon:'40'},
        /* 30% */ {name:'ลูกชิ้นปลา(แหลม) เกรดพรีเมี่ยม',priceAgent: '120 บาท/กก.',price: '145 บาท/กก.',img:'ลูกชิ้นปลาแหลม-เกรดพรีเมี่ยม-บู๊ฮวด.jpg',size:'1 กก.',priceToon:'98'},
        /* 30% */ {name:'ลูกชิ้นปลา(กลม) เกรดพรีเมี่ยม',priceAgent: '120 บาท/กก.',price: '145 บาท/กก.',img:'ลูกชิ้นปลากลม-เกรดพรีเมี่ยม-บู๊ฮวด.jpg',size:'1 กก.',priceToon:'98'},
        /* 30% */ {name:'เส้นปลา บู๊ฮวด',priceAgent: '135 บาท/กก.',price: '140 บาท/กก.',img:'เส้นปลา-บู๊ฮวด.jpg',size:'1 กก.',priceToon:'120'},
        /* 30% */ {name:'เกี๊ยวปลา',priceAgent: '155 บาท/กก.',price: '169 บาท/กก.',img:'เกี๊ยวปลา.jpg',size:'1 กก.',priceToon:'125'},
        /* 30% */ {name:'ลูกชิ้นกุ้ง ทวีวงษ์',priceAgent: '64 บาท/แพ็ค',price: '64 บาท/แพ็ค',img:'ลูกชิ้นกุ้ง-ทวีวงษ์.webp',size:'500 กรัม',priceToon:'0'},
        /* 30% */ {name:'ลูกชิ้นกุ้ง SP',priceAgent: '34 บาท/แพ็ค',price: '49 บาท/แพ็ค',img:'ลูกชิ้นกุ้ง-sp.jpg',size:'500 กรัม',priceToon:'30'},
        /* 30% */ {name:'ลูกชิ้นกุ้ง บู๊ฮวด',priceAgent: '93 บาท/กก.',price: '100 บาท/กก.',img:'ลูกชิ้นกุ้ง-บู๊ฮวด.jpg',size:'500 กรัม, 1 กก.',priceToon:'39'},
        /* 30% */ {name:'ลูกชิ้นกุ้ง ตั้งเจริญ',priceAgent: '68 บาท/กก.',price: '75 บาท/กก.',img:'ลูกชิ้นกุ้ง-ตั้งเจริญ.jpg',size:'500 กรัม',priceToon:'28'},
        /* 30% */ {name:'ลูกชิ้นปู ตั้งเจริญ',priceAgent: '68 บาท/กก.',price: '75 บาท/กก.',img:'ลูกชิ้นปู-ตั้งเจริญ.jpg',size:'500 กรัม',priceToon:'28'},
        /* 30% */ {name:'ลูกชิ้นปลาหมึก ตั้งเจริญ',priceAgent: '68 บาท/กก.',price: '75 บาท/กก.',img:'ลูกชิ้นปลาหมึก-ตั้งเจริญ.jpg',size:'500 กรัม',priceToon:'28'},
        /* 30% */ {name:'ลูกชิ้นเต้าหู้หมู ตั้งเจริญ',priceAgent: '80 บาท/กก.',price: '85 บาท/กก.',img:'ลูกชิ้นเต้าหู้หมู-ตั้งเจริญ.jpg',size:'500 กรัม',priceToon:'32.5'},
        /* 30% */ {name:'ลูกชิ้นเอ็นหมูเกรด A หมูยิ้ม',priceAgent: '72 บาท/กก.',price: '79 บาท/กก.',img:'ลูกชิ้นเอ็นหมูเกรดเอ-หมูยิ้ม.jpg',size:'1 กก.',priceToon:'52'},
        /* 30% */ {name:'ลูกชิ้นเอ็นหมูเกรด B หมูยิ้ม',priceAgent: '62 บาท/กก.',price: '69 บาท/กก.',img:'ลูกชิ้นเอ็นหมูยิ้ม-เกรด-b.jpg',size:'1 กก.',priceToon:'42'},
        /* 30% */ {name:'ลูกชิ้นหมูเกรด B หมูยิ้ม',priceAgent: '62 บาท/กก.',price: '69 บาท/กก.',img:'ลูกชิ้นเอ็นหมูเกรดบี-หมูยิ้ม.jpg',size:'1 กก.',priceToon:'42'},
        /* 30% */ {name:'ลูกชิ้นหมูเกรด A หมูยิ้ม',priceAgent: '72 บาท/กก.',price: '79 บาท/กก.',img:'ลูกชิ้นหมู-เกรดเอ-หมูยิ้ม.jpg',size:'1 กก.',priceToon:'52'},
        /* 30% */ {name:'ลูกชิ้นหมูเกรด A หมูยิ้ม กลมใหญ่',priceAgent: '72 บาท/กก',price: '79 บาท/กก',img:'ลูกชิ้นหมูยิ้มเกรด-a-กลมใหญ่.jpg',size:'1 กก',priceToon:'52'},
        /* 30% */ {name:'ลูกชิ้นหมูเกรด A ตราหมูยิ้ม 77',priceAgent: '85 บาท/กก.',price: '95 บาท/กก.',img:'ลูกชิ้นหมู-สำหรับก๋วยเตี๋ยว-ตราหมูยิ้ม77.jpg',size:'500 กรัม, 1 กก.',priceToon:'70'},
        /* 30% */ {name:'ลูกชิ้นเอ็นไก่เกรด A ไก่ยิ้ม',priceAgent: '72 บาท/กก.',price: '79 บาท/กก.',img:'ลูกชิ้นเอ็นไก่เกรดเอ-ไก่ยิ้ม.jpg',size:'1 กก.',priceToon:'52'},
        /* 30% */ {name:'ลูกชิ้นไก่เกรด A ไก่ยิ้ม',priceAgent: '72 บาท/กก.',price: '79 บาท/กก.',img:'ลูกชิ้นไก่เกรดเอ-ไก่ยิ้ม.jpg',size:'1 กก.',priceToon:'52'},
        /* 30% */ {name:'ลูกชิ้นไก่ แหลมทอง',priceAgent: '60 บาท/กก.',price: '65 บาท/กก.',img:'ลูกชิ้นไก่-แหลมทอง.webp',size:'1 กก.',priceToon:'52'},
        /* 30% */ //{name:'ลูกชิ้นไก่ แหลมทอง',priceAgent: '52-55 บาท/กก.',price: '69 บาท/กก.',img:'ลูกชิ้นไก่-แหลมทอง.jpg',size:'1 กก.',priceToon:'44.5'},
        /* 30% */ {name:'ลูกชิ้นเอ็นเนื้อเกรด A เนื้อยิ้ม',priceAgent: '72 บาท/กก.',price: '79 บาท/กก.',img:'ลูกชิ้นเอ็นเนื้อ-เกรดเอ-เนื้อยิ้ม.jpg',size:'1 กก.',priceToon:'52'},
        /* 30% */ {name:'ลูกชิ้นเนื้อยิ้ม เกรด A',priceAgent: '72 บาท/กก.',price: '79 บาท/กก.',img:'ลูกชิ้นเอ็นเนื้อยิ้ม-เกรด-b.jpg',size:'1 กก',priceToon:'52'},
        /* 30% */ {name:'ลูกชิ้นเอ็นเนื้อยิ้ม เกรด B เนื้อยิ้ม',priceAgent: '62 บาท/กก.',price: '69 บาท/กก.',img:'ลูกชิ้นเนื้อยิ้ม-เกรด-a.jpg',size:'1 กก.',priceToon:'42'},
        /* 30% */ {name:'ลูกชิ้นเนื้อยิ้ม เกรด B',priceAgent: '62 บาท/กก.',price: '69 บาท/กก.',img:'ลูกชิ้นเนื้อยิ้ม-เกรด-b.jpg',size:'1 กก',priceToon:'42'},
        /* 30% */ {name:'ลูกชิ้นเนื้อ เกรด A ตราชาววัง',priceAgent: '95 บาท/กก.',price: '100 บาท/กก.',img:'ลูกชิ้นเนื้อ-ตราชาววัง.jpg',size:'1 กก.',priceToon:'80'},
        /* 30% */ {name:'ปูอัด เบนโตะ ทวีวงษ์',priceAgent: '67 บาท/แพ็ค',price: '85 บาท/แพ็ค',img:'ปูอัด-เบนโตะ-ทวีวงษ์.jpg',size:'500 กรัม',priceToon:'64.5'},
        /* 30% */ {name:'ปูอัด เบนโตะ(แพ็คใส) ทวีวงษ์',priceAgent: '67 บาท/แพ็ค',price: '85 บาท/แพ็ค',img:'ปูอัดเบนโตะแพ็คใส-ทวีวงษ์.jpg',size:'500 กรัม',priceToon:'64.5'},
        /* 30% */ {name:'ปูอัด เบนโตะพลัส ทวีวงษ์',priceAgent: '49 บาท/แพ็ค',price: '65 บาท/แพ็ค',img:'ปูอัด-เบนโตะพลัส-ทวีวงษ์.jpg',size:'250 กรัม',priceToon:'44'},
        /* 30% */ {name:'เมก้าแครบ ทวีวงษ์',priceAgent: '59 บาท/แพ็ค',price: '69 บาท/แพ็ค',img:'เมก้าแครบ-ทวีวงษ์.jpg',size:'500 กรัม',priceToon:'53.5'},
        /* 30% */ {name:'ปูอัด คานิจัง ทวีวงษ์',priceAgent: '46 บาท/แพ็ค',price: '60 บาท/แพ็ค',img:'ปูดอัด-คานิจัง-ทวีวงษ์.jpg',size:'500 กรัม',priceToon:'42'},
        /* 30% */ {name:'ปูอัด คานิจัง 12cm ทวีวงษ์',priceAgent: '46 บาท/แพ็ค',price: '60 บาท/แพ็ค',img:'ปูอัดคานิจัง-12cm-ทวีวงษ์.jpg',size:'500 กรัม',priceToon:'42'},
        /* 30% */ {name:'ปลาหมึกหลอด(ซองส้ม) ทวีวงษ์',priceAgent: '51 บาท/แพ็ค',price: '60 บาท/แพ็ค',img:'ปลาหมึกหลอดส้ม-ทวีวงษ์.jpg',size:'500 กรัม',priceToon:'46'},
        /* 30% */ {name:'ปลาหมึกหลอด(ซองฟ้า) ทวีวงษ์',priceAgent: '53 บาท/แพ็ค',price: '65 บาท/แพ็ค',img:'ปลาหมึกหลอดฟ้า-ทวีวงษ์.jpg',size:'500 กรัม',priceToon:'48'},
        /* 30% */ {name:'กุ้งระเบิด ทวีวงษ์',priceAgent: '62 บาท/แพ็ค',price: '79 บาท/แพ็ค',img:'กุ้งระเบิด-ทวีวงษ์.jpg',size:'500 กรัม',priceToon:'57.5'},
        /* 30% */ {name:'กุ้งแม็กนั่ม ทวีวงษ์',priceAgent: '52 บาท/แพ็ค',price: '65 บาท/แพ็ค',img:'กุ้งแม็กนั่ม-ทวีวงษ์.jpg',size:'500 กรัม',priceToon:'47.5'},
        /* 30% */ {name:'เต้าหู้ปลา ทวีวงษ์',priceAgent: '62 บาท/แพ็ค',price: '75 บาท/แพ็ค',img:'เต้าหู้ปลา-ทวีวงษ์.jpg',size:'500 กรัม',priceToon:'56.5'},
        /* 30% */ {name:'เต้าหู้ปลา SP',priceAgent: '68 บาท/กก.',price: '79 บาท/กก.',img:'เต้าหู้ปลา-sp.jpg',size:'500 กรัม',priceToon:'30'},
        /* 30% */ {name:'กุ้งพันสาหร่าย SP',priceAgent: '42 บาท/แพ็ค',price: '55 บาท/แพ็ค',img:'กุ้งสาหร่าย-sp.jpg',size:'500 กรัม',priceToon:'37.5'},
        /* 30% */ {name:'เปาเปา ใส้ปูอัด ทวีวงษ์',priceAgent: '67 บาท/แพ็ค',price: '80 บาท/แพ็ค',img:'เปาๆ-ไส้ปูอัด.jpg',size:'500 กรัม',priceToon:'62.5'},
        /* 30% */ {name:'ซาลาเปาปลา ไส้ครีมชีส ทวีวงษ์',priceAgent: '70 บาท/แพ็ค',price: '85 บาท/แพ็ค',img:'ซาลาเปาปลา-ไส้ครีมชีส-ทวีวงษ์.jpg',size:'500 กรัม',priceToon:'65'},
        /* 30% */ {name:'ซาลาเปาปลา ไส้ไข่กุ้ง ทวีวงษ์',priceAgent: '75 บาท/แพ็ค',price: '89 บาท/แพ็ค',img:'ซาลาเปาปลาไส้ไข่กุ้ง.jpg',size:'500 กรัม',priceToon:'70'},
        /* 30% */ {name:'ไซม่อนซัง ทวีวงษ์',priceAgent: '64 บาท/แพ็ค',price: '79 บาท/แพ็ค',img:'ไซม่อนซัง.jpg',size:'500 กรัม',priceToon:'59'},
        /* 30% */ //{name:'ยำสาหร่าย วากาเมะจัง ทวีวงษ์',priceAgent: '75-80 บาท/แพ็ค',price: '90 บาท/แพ็ค',img:'ยำสาหร่ายวากาเมะจัง.jpg',size:'500 กรัม',priceToon:'70'},
        /* 30% */ {name:'ลูกชิ้นปลา ทวีวงษ์',priceAgent: '53 บาท/แพ็ค',price: '65 บาท/แพ็ค',img:'ลูกชิ้นปลา-ทวีวงษ์.jpg',size:'500 กรัม',priceToon:'48'},
        /* 30% */ {name:'ลูกชิ้นปลาไจแอนท์ ทวีวงษ์',priceAgent: '53 บาท/แพ็ค',price: '65 บาท/แพ็ค',img:'ลูกชิ้นปลาไจแอนท์-ทวีวงษ์.jpg',size:'500 กรัม',priceToon:'48'},
        /* 30% */ {name:'ทอดมันปลา ทวีวงษ์',priceAgent: '58 บาท/แพ็ค',price: '69 บาท/แพ็ค',img:'ทอดมันปลา-ทวีวงษ์.jpg',size:'500 กรัม',priceToon:'53.5'},
        /* 30% */ {name:'ทอดมันหมู หมู 8 ตัว',priceAgent: '42 บาท/แพ็ค',price: '52 บาท/แพ็ค',img:'ทอดมันหมู-หมู-8-ตัว.jpg',size:'500 กรัม',priceToon:'35.5'},
        /* 30% */ //{name:'ลูกชิ้นไก่ระเบิด แหลมทอง',priceAgent: '46-50 บาท/กก.',price: '65 บาท/กก.',img:'ไก่ระเบิด-แหลมทอง.jpg',size:'1 กก.',priceToon:'39'},
        /* 30% */ //{name:'ค็อกเทลตำนาน แหลมทอง',priceAgent: '56 บาท/แพ็ค',price: '60 บาท/แพ็ค',img:'ไส้กรอกแดงตำนาน-แหลมทอง.jpg',size:'1 กก.',priceToon:'49'},
        /* 30% */ {name:'คอกเทลไก่รมควัน แหลมทอง',priceAgent: '58 บาท/แพ็ค',price: '69 บาท/แพ็ค',img:'คอกเทลไก่รมควัน-แหลมทอง.jpg',size:'1 กก.',priceToon:'49'},
        /* 30% */ //{name:'ค็อกเทลรสนม แหลมทอง',priceAgent: '42 บาท/แพ็ค',price: '60 บาท/แพ็ค',img:'ค็อกเทลรสนม-แหลมทอง.jpg',size:'500 กรัม',priceToon:'35'},
        /* 30% */ //{name:'ฮอทดอก LPF แหลมทอง',priceAgent: '50 บาท/กก.',price: '65 บาท/กก.',img:'ฮอทดอก-lpf-แหลมทอง.jpg',size:'1 กก.',priceToon:'42.5'},
        /* 30% */ //{name:'ฮอทดอกเชดดาร์ชีส แหลมทอง',priceAgent: '52 บาท/แพ็ค',price: '60 บาท/แพ็ค',img:'ฮอทดอกเชดดาร์ชีส-แหลมทอง.jpg',size:'600 กรัม',priceToon:'45'},
        /* 30% */ //{name:'ฮอทดอกซุปเปอร์โกลด์ แหลมทอง',priceAgent: '46 บาท/แพ็ค',price: '55 บาท/แพ็ค',img:'ฮอทดอก-ซุปเปอร์โกลด์.jpg',size:'600 กรัม',priceToon:'38'},
        /* 30% */ //{name:'จัมโบ้รมควัน 15 ชิ้น แหลมทอง',priceAgent: '70 บาท/กก.',price: '85 บาท/กก.',img:'จัมโบ้รมควัน-15-ชิ้น-แหลมทอง.jpg',size:'1 กก.',priceToon:'65'},
        /* 30% */ //{name:'ค็อกเทลซุปเปอร์โกลด์ แหลมทอง',priceAgent: '40 บาท/แพ็ค',price: '55 บาท/แพ็ค',img:'ค็อกเทลหนังกรอบ-แหลมทอง.jpg',size:'500 กรัม',priceToon:'36'},
        /* 30% */ //{name:'แฟรงค์หนังกรอบ แหลมทอง',priceAgent: '40 บาท/แพ็ค',price: '55 บาท/แพ็ค',img:'แฟรงเฟอร์เตอร์หนังกรอบ-แหลมทอง.jpg',size:'500 กรัม',priceToon:'34'},
        /* 30% */ //{name:'จัมโบ้ชีสไบท์ แหลมทอง',priceAgent: '80 บาท/กก.',price: '89 บาท/กก.',img:'จัมโบ้ชีสไบท์-แหลมทอง.jpg',size:'1 กก.',priceToon:'75'},
        /* 30% */ //{name:'จัมโบ้ซุปเปอร์โกลด์ 15 ชิ้น แหลมทอง',priceAgent: '70 บาท/กก.',price: '80 บาท/กก.',img:'จัมโบ้ซุปเปอร์โกลด์15-แหลมทอง.jpg',size:'1 กก.',priceToon:'65'},
        /* 30% */ //{name:'ไก่ยอ แหลมทอง',priceAgent: '58 บาท/กก.',price: '65 บาท/กก.',img:'ไก่ยอ-แหลมทอง.jpg',size:'1 กก.',priceToon:'53.5'},
        /* 30% */ {name:'ไก่จ๊อ 5 ดาว',priceAgent: '140 บาท/กก.',price: '155 บาท/กก.',img:'ไก่จ๊อ-5-ดาว.jpg',size:'1 กก.',priceToon:'117'},
        /* 30% */ {name:'ไก่จ๊อ 5 ดาว (500 กรัม)',priceAgent: '73 บาท/แพ็ค',price: '85 บาท/แพ็ค',img:'ไก่จ๊อ-5-ดาว-500-กรัม.jpg',size:'500 กรัม',priceToon:'61'},
        /* 30% */ {name:'ไส้กรอกหนังกรอบ(หมูผสมไก่) JPM',priceAgent: '85 บาท/กก.',price: '90 บาท/กก.',img:'ไส้กรอก-jpm.jpg',size:'1 กก.',priceToon:'60'},
        /* 30% */ {name:'ไส้กรอกหนังกรอบ(หมูผสมไก่) JPM จัมโบ้',priceAgent: '85 บาท/กก.',price: '90 บาท/กก.',img:'ไส้กรอก-jpm-จัมโบ้.jpg',size:'1 กก.',priceToon:'60'},
        /* 30% */ //{name:'วิวไก่ทอง JPM',priceAgent: '83 บาท/กก.',price: '88 บาท/กก.',img:'วิวไก่ทอง-jpm.jpg',size:'1 กก.',priceToon:'63'},
        /* 30% */ //{name:'บิ๊กค็อกเทลหนังกรอบ(หมูผสมไก่) JPM',priceAgent: '85 บาท/กก.',price: '90 บาท/กก.',img:'ไส้กรอกหนังกรอบหมูผสมไก่-บิ๊กค็อกเทล-jpm.jpg',size:'1 กก.',priceToon:'65'},
        /* 30% */ {name:'ไบท์ชีสไก่ JPM',priceAgent: '95 บาท/กก.',price: '100 บาท/กก.',img:'ไบท์ชีสไก่-JPM.jpg',size:'1 กก.',priceToon:'81'},
        /* 30% */ {name:'โบโลน่าพริกไก่ JPM',priceAgent: '27 บาท/แพ็ค',price: '35 บาท/แพ็ค.',img:'โบโลน่าพริกไก่-jpm.jpg',size:'300 กรัม',priceToon:'26'},
        /* 30% */ {name:'โบโลน่าไก่ (ไม่พริก) JPM',priceAgent: '27 บาท/แพ็ค',price: '35 บาท/แพ็ค.',img:'โบโลน่าไก่ไม่พริก-jpm.jpg',size:'300 กรัม',priceToon:'26'},
        /* 30% */ {name:'เปาะเปี๊ยะทอด เหล่าเจียไต๋',priceAgent: '35 บาท/แพ็ค',price: '45 บาท/แพ็ค.',img:'เปาะเปี๊ยะทอด-เหล่าเจียไต๋.jpg',size:'500 กรัม',priceToon:'31'},
        /* 30% */ {name:'น้ำจิ้มมะขาม จอมยุทธ์16',priceAgent: '25 บาท/กก.',price: '29 บาท/กก.',img:'น้ำจิ้มมะขาม-จอมยุทธ์.jpg',size:'1 กก.',priceToon:'21.25'},
                {name:'น้ำจิ้มจอมยุทธ์ ถุงส้ม',img:'น้ำจิ้มจอมยุทธ์-ถุงส้ม.webp',size:'1 กก.',priceToon:0,priceAgent:'23 บาท/กก.'},
        /* 30% */ {name:'น้ำจิ้มลูกชิ้น ตรา 555',priceAgent: '25 บาท/กก.',price: '29 บาท/กก.',img:'น้ำจิ้ม-ตอง5.jpg',size:'1 กก.',priceToon:'23'},
        /* 30% */ {name:'น้ำจิ้ม กระต่ายคู่',priceAgent: '25 บาท/กก.',price: '29 บาท/กก.',img:'น้ำจิ้ม-กระต่ายคู่.jpg',size:'1 กก.',priceToon:'21'},
        /* 30% */ {name:'น้ำจิ้มมะขาม(เล็ก) จอมยุทธ์16',priceAgent: '10 บาท/แพ็ค.',price: '12 บาท/แพ็ค',img:'น้ำจิ้มจอมยุทธ์16-แบบถุงเล็ก.jpg',size:'250 กรัม',priceToon:'7'}, // 4 แพ็คโล
        /* 30% */ {name:'น้ำจิ้มสูตรดั้งเดิม(เล็ก) จอมยุทธ์16',priceAgent: '10 บาท/แพ็ค.',price: '12 บาท/แพ็ค',img:'น้ำจิ้มจอมยุทธ์-สูตรดั้งเดิมซองเล็ก.jpg',size:'250 กรัม',priceToon:'6'}, // 4 แพ็คโล
        /* 30% */ {name:'น้ำจิ้มมะขาม(ขวดเล็ก) จอมยุทธ์16',priceAgent: '9 บาท/ขวด.',price: '15 บาท/ขวด',img:'น้ำจิ้มมะขาม-จอมยุทธ์-ขวดเล็ก.webp',size:'220 กรัม',priceToon:'7.5'}, // 4 ขวดโล
        /* 30% */ {name:'น้ำจิ้มสูตรดั้งเดิม(ขวดเล็ก) จอมยุทธ์16',priceAgent: '9 บาท/ขวด.',price: '15 บาท/ขวด',img:'น้ำจิ้ม-จอมยุทธ์-ขวดเล็ก.webp',size:'220 กรัม',priceToon:'7.1'}, // 4 ขวดโล 2 โหลทุน 170
        /* 30% */ //{name:'กุ้งห่อสาหร่ายปัทมาพร',priceAgent: '43 บาท/แพ็ค',price: '45 บาท/แพ็ค',img:'กุ้งพันสาหร่ายท่าฉลอม.jpg',size:'500 กรัม',priceToon:'36'},
        /* 30% */ //{name:'จ้อกุ้งท่าฉลอม',priceAgent: '73 บาท/แพ็ค',price: '80 บาท/แพ็ค',img:'จ้อกุ้งท่าฉลอม.jpg',size:'1 กก',priceToon:'58'},
        /* 30% */ //{name:'จ้อไก่ท่าฉลอม',priceAgent: '73 บาท/แพ็ค',price: '80 บาท/แพ็ค',img:'จ้อไก่ท่าฉลอม.jpg',size:'1 กก',priceToon:'58'},
        /* 30% */ //{name:'จ้อปูท่าฉลอม',priceAgent: '73 บาท/แพ็ค',price: '80 บาท/แพ็ค',img:'จ้อปูท่าฉลอม.jpg',size:'1 กก',priceToon:'58'},
        /* 30% */ //{name:'ซูปเปอร์จัมโบ้หมูหนังกรอบ',priceAgent: '67 บาท/แพ็ค',price: '75 บาท/แพ็ค',img:'ซูปเปอร์จัมโบ้หมูหนังกรอบ.jpg',size:'1 กก',priceToon:'62'},
        /* 30% */ //{name:'เต้าหู้หมู AA',priceAgent: '80 บาท/กก.',price: '85 บาท/กก.',img:'เต้าหู้หมู-aa.jpg',size:'500 กรัม',priceToon:'66'},
        /* 30% */ {name:'เต้าหู้หมู JJ',priceAgent: '80 บาท/กก.',price: '85 บาท/กก.',img:'เต้าหู้หมู-jj.jpg',size:'500 กรัม',priceToon:'66'},
        /* 30% */ //{name:'น้ำจิ้มซีฟู้ดรสเด็ด',priceAgent: '20 บาท/แพ็ค',price: '30 บาท/แพ็ค',img:'น้ำจิ้มซีฟู้ดรสเด็ด.jpg',size:'220 กรัม',priceToon:'18'},
        /* 30% */ {name:'ปลาทิพย์ทวีวงษ์',priceAgent: '53 บาท/แพ็ค',price: '60 บาท/แพ็ค',img:'ปลาทิพย์ทวีวงษ์.jpg',size:'500 กรัม',priceToon:'48'},
        /* 30% */ {name:'ปลาเส้น 5 ดาว (แพ็ค 10 เส้น)',priceAgent: '160 บาท/กก',price: '180 บาท/กก',img:'ปลาเส้น-5-ดาว.jpg',size:'1 กก',priceToon:'140'},
        /* 30% */ //{name:'ปลาเส้นเกรด A',priceAgent: '19 บาท/เส้น',price: '25 บาท/เส้น',img:'ปลาเส้นเกรดเอ.jpg',size:'200 กรัม',priceToon:'16'},
        /* 30% */ //{name:'ฟุตลอง ดับเบิ้ลชีส 5 ชิ้น',priceAgent: '30 บาท/แพ็ค',price: '35 บาท/แพ็ค',img:'ฟุตลอง-ดับเบิ้ลชีส-5-ชิ้น.jpg',size:'400 กรัม',priceToon:'27'},
        /* 30% */ //{name:'แฟรงค์ไก่รมควัน PBM',priceAgent: '30 บาท/แพ็ค',price: '35 บาท/แพ็ค',img:'แฟรงค์ไก่รมควัน-pbm.jpg',size:'500 กรัม',priceToon:'28'},
        /* 30% */ //{name:'ลูกชิ้นปลาหมึกปัทมาพร',priceAgent: '65 บาท/กก.',price: '70 บาท/กก.',img:'ลูกชิ้นปลาหมึกปัทมาพร.jpg',size:'500 กรัม',priceToon:'57'},
        /* 30% */ //{name:'ลูกชิ้นปูอัด',priceAgent: '30 บาท/แพ็ค',price: '40 บาท/แพ็ค',img:'ลูกชิ้นปูอัด.jpg',size:'450 กรัม',priceToon:'28'},
        /* 30% */ //{name:'ลูกชิ้นสาหร่าย',priceAgent: '30 บาท/แพ็ค',price: '40 บาท/แพ็ค',img:'ลูกชิ้นสาหร่าย.jpg',size:'450 กรัม',priceToon:'28'},
        /* 30% */ //{name:'ลูกทอดปลากรายแดง',priceAgent: '42 บาท/กก',price: '49 บาท/กก',img:'ลูกทอดปลากรายแดง.jpg',size:'1 กก',priceToon:'33'},
        /* 30% */ //{name:'ไส้กรอกรมควันจัมโบ้โบราณ',priceAgent: '65 บาท/กก',price: '75 บาท/กก',img:'ไส้กรอกรมควันจัมโบ้โบราณ.jpg',size:'1 กก',priceToon:'60'},
        /* 30% */ {name:'ไส้อั่วสมุนไพรจิ๋ว',priceAgent: '57 บาท/แพ็ค',price: '65 บาท/แพ็ค',img:'ไส้อั่วสมุทรไพรจิ๋ว.jpg',size:'500 กรัม',priceToon:'47'},
        /* 30% */ {name:'หมูยออุบล',priceAgent: '27 บาท/แพ็ค',price: '30 บาท/แพ็ค',img:'หมูยออุบล.jpg',size:'400 กรัม',priceToon:'20'},
        /* 30% */ {name:'แหนมย่างหมู แพ็ค 5 ชิ้น',priceAgent: '40.5 บาท/แพ็ค',price: '45 บาท/แพ็ค',img:'แหนมย่างหมู-แพ็ค-5-ชิ้น.jpg',size:'450 กรัม',priceToon:'31'},
        /* 30% */ //{name:'โอ้โห ไส้กรอกไก่ทอง',priceAgent: '90 บาท/กก',price: '95 บาท/กก',img:'โอ้โห-ไส้กรอกไก่ทอง.jpg',size:'1 กก',priceToon:'76'},
        /* 30% */ //{name:'ฮอทดอกแหนมหนังกรอบ',priceAgent: '70 บาท/กก',price: '85 บาท/กก',img:'ฮอทดอกแหนมหนังกรอบ.jpg',size:'1 กก',priceToon:'67'},
        /* 30% */ {name:'PP ไส้กรอกหนังกรอบไก่ดี',priceAgent: '85 บาท/กก',price: '85 บาท/กก',img:'pp-ไส้กรอกหนังกรอบไก่ดี.jpg',size:'1 กก',priceToon:'65'},
        /* 30% */ {name:'PP ไส้กรอกหนังกรอบหมูดี จัมโบ้',priceAgent: '85 บาท/กก',price: '85 บาท/กก',img:'pp-ไส้กรอกหนังกรอบหมูดี-จัมโบ้.jpg',size:'1 กก',priceToon:'65'},
        /* 30% */ //{name:'ปลาระเบิด ส มนัส',priceAgent: '49 บาท/กก',price: '59 บาท/กก',img:'ปลาระเบิด-ส-มนัส.jpg',size:'1 กก',priceToon:'39'},
        /* 30% */ {name:'ลูกชิ้นปลาหลี',priceAgent: '84 บาท/กก',price: '90 บาท/กก',img:'ลูกชิ้นปลาหลี.jpg',size:'1 กก',priceToon:'70'},
        /* 30% */ //{name:'คอกเทลซุเปอร์หมู หนังกรอบ',priceAgent: '68 บาท/กก',price: '80 บาท/กก',img:'คอกเทลซุเปอร์หมูหนังกรอบ.jpg',size:'1 กก',priceToon:'63'},
        /* 30% */ //{name:'ปูอัดทาอิจิ',priceAgent: '52 บาท/แพ็ค',price: '65 บาท/แพ็ค',img:'ปูอัดทาอิจิ.jpg',size:'500 กรัม',priceToon:'50'},
        /* 30% */ //{name:'ลูกชิ้นทอดมัน ปลาหมึก',priceAgent: '30 บาท/แพ็ค',price: '40 บาท/แพ็ค',img:'ลูกชิ้นทอดมันปลาหมึก.jpg',size:'450 กรัม',priceToon:'28'},
        /* 30% */ {name:'คอกเทลแดงไก่ โอ้โห',priceAgent: '55 บาท/กก.',price: '65 บาท/กก.',img:'คอกเทลแดงไก่-โอ้โห.jpg',size:'1 กก.',priceToon:'42'},
        /* 30% */ //{name:'จ๊อหมูชีส แม่พลอยบุญ',priceAgent: '30 บาท/แพ็ค',price: '35 บาท/แพ็ค',img:'จ๊อหมูชีส-แม่พลอยบุญ.jpg',size:'400 กรัม',priceToon:'28'},
        /* 30% */ {name:'ปูอัดคานิจัง 250 กรัม',priceAgent: '24 บาท/แพ็ค',price: '25 บาท/แพ็ค',img:'ปูอัดคานิจัง-250-กรัม.jpg',size:'250 กรัม',priceToon:'21'},
        /* 30% */ //{name:'ปูอัดจัมโบ้ทาอิจิ',priceAgent: '50 บาท/แพ็ค',price: '60 บาท/แพ็ค',img:'ปูอัดจัมโบ้ทาอิจิ.jpg',size:'500 กรัม',priceToon:'48'},
        /* 30% */ //{name:'ปูอัดทาอิจิพร้อมวาซาบิ',priceAgent: '29 บาท/แพ็ค',price: '35 บาท/แพ็ค',img:'ปูอัดทาอิจิพร้อมวาซาบิ.jpg',size:'250 กรัม',priceToon:'27'},
        /* 30% */ //{name:'เปาะเปี๊ยะกุ้ง แม่พลอยบุญ',priceAgent: '48 บาท/แพ็ค',price: '52 บาท/แพ็ค',img:'เปาะเปี๊ยะกุ้งแม่พลอยบุญ.jpg',size:'800 กรัม',priceToon:'35'},
        /* 30% */ //{name:'เปาะเปี๊ยะวุ้นเส้น แม่พลอยบุญ',priceAgent: '48 บาท/แพ็ค',price: '52 บาท/แพ็ค',img:'เปาะเปี๊ยะวุ้นเส้นแม่พลอยบุญ.jpg',size:'800 กรัม',priceToon:'35'},
        /* 30% */// {name:'ลูกชิ้นปลาหมึก แม่พลอยบุญ',priceAgent: '30 บาท/แพ็ค',price: '40 บาท/แพ็ค',img:'ลูกชิ้นปลาหมึก-แม่พลอยบุญ.jpg',size:'450 กรัม',priceToon:'27'},
        /* 30% */// {name:'ลูกชิ้นปูอัด แม่พลอยบุญ',priceAgent: '30 บาท/แพ็ค',price: '40 บาท/แพ็ค',img:'ลูกชิ้นปูอัดแม่พลอยบุญ.jpg',size:'450 กรัม',priceToon:'27'},
        /* 30% */ {name:'ไส้กรอกไก่คลาสสิก โอ้โห',priceAgent: '55 บาท/กก.',price: '59 บาท/กก.',img:'ไส้กรอกไก่คลาสสิก-โอ้โห.jpg',size:'500 กรัม',priceToon:'23'},
        /* 30% */ {name:'หมูเด้ง เปิปพิสดาร',priceAgent: '69 บาท/กก.',price: '75 บาท/กก.',img:'หมูเด้ง-เปิปพิสดาร.jpg',size:'500 กรัม',priceToon:'29'},
        /* 30% */ {name:'หมูยอจิ๋วดอนเมือง',priceAgent: '80 บาท/แพ็ค',price: '90 บาท/แพ็ค',img:'หมูยอจิ๋วดอนเมือง.jpg',size:'900 กรัม',priceToon:'70'},
        /* 30% */ //{name:'หมูยออุบล 24 ชิ้น',priceAgent: '58 บาท/แพ็ค',price: '65 บาท/แพ็ค',img:'หมูยออุบล-24-ชิ้น.jpg',size:'925 กรัม',priceToon:'55'},
        /* 30% */ //{name:'หมูยออุบลจิ๋ว แม่พลอย',priceAgent: '29 บาท/แพ็ค',price: '35 บาท/แพ็ค',img:'หมูยออุบลจิ๋ว.jpg',size:'450 กรัม',priceToon:'26'},
        /* 30% */ {name:'jpm คอกเทลไก่',priceAgent: '46 บาท/แพ็ค',price: '50 บาท/แพ็ค',img:'jpm-คอกเทลไก่.jpg',size:'500 กรัม',priceToon:'36'},
        /* 30% */ //{name:'jpm คอกเทลหนังกรอบ',priceAgent: '24 บาท/แพ็ค',price: '30 บาท/แพ็ค',img:'jpm-คอกเทลหนังกรอบ-250-กรัม.jpg',size:'250 กรัม',priceToon:'21'},
        /* 30% */ //{name:'jpm ไบท์กระเพรา 7 ชิ้น',priceAgent: '21 บาท/แพ็ค',price: '30 บาท/แพ็ค',img:'jpm-ไบท์กระเพรา-7-ชิ้น-250-กรัม.jpg',size:'250 กรัม',priceToon:'18'},
        /* 30% */ {name:'jpm ไบท์กระเพรา',priceAgent: '83 บาท/กก',price: '90 บาท/กก',img:'jpm-ไบท์กระเพรา.jpg',size:'1 กก',priceToon:'63'},
        /* 30% */ //{name:'jpm ไบท์แฮม 250 กรัม',priceAgent: '25 บาท/แพ็ค',price: '30 บาท/แพ็ค',img:'jpm-ไบท์แฮม-250-กรัม.jpg',size:'250 กรัม',priceToon:'21'},
        /* 30% */ {name:'jpm ไบท์แฮม',priceAgent: '93 บาท/กก',price: '95 บาท/กก',img:'jpm-ไบท์แฮม.jpg',size:'1 กก',priceToon:'78'},
        {name:'ลูกชิ้นปลา ซีเฟรช',img:'ลูกชิ้นปลา-ซีเฟรช.webp',size:'1 กก.',priceToon:0,priceAgent:'57 บาท/กก.'},
{name:'ลอดช่อง วัดเจษ',img:'ลอดช่อง-วัดเจษ.webp',size:'400 กรัม',priceToon:0,priceAgent:'74 บาท/แพ็ค'},
{name:'ทับทิมกรอบ วัดเจษ',img:'ทับทิมกรอบ-วัดเจษ.webp',size:'180 กรัม',priceToon:0,priceAgent:'35 บาท/แพ็ค'},
{name:'สลิ่ม วัดเจษ',img:'สลิ่ม-วัดเจษ.webp',size:'180 กรัม',priceToon:0,priceAgent:'35 บาท/แพ็ค'},
{name:'เบทาโกร จัมโบ้นม',img:'เบทาโกร-จัมโบ้นม.webp',size:'1 กก.',priceToon:0,priceAgent:'73 บาท/กก.'},
{name:'หมูยอเส้นยาว ไฮเทค',img:'หมูยอเส้นยาว-สยามไฮเทค.webp',size:'1 กก.',priceToon:0,priceAgent:'73 บาท/กก.'},
{name:'ลูกชิ้นเอ็นหมู 3 ตัว (ปอง)',img:'ลูกชิ้นเอ็นหมู-3-ตัว-ปอง.webp',size:'1 กก.',priceToon:0,priceAgent:'86 บาท/กก.'},
{name:'เต้าหู้หลอด แม่ครัวชูใจ',img:'เต้าหู้หลอด-แม่ครัวชูใจ.webp',size:'130 กรัม',priceToon:0,priceAgent:'7 บาท/หลอด'},
 {name:'ปลาระเบิด ปังปัง (กลม)',img:'ปลาระเบิด-ปังปัง-กลม.webp',size:'1 กก.',priceToon:0,priceAgent:'40 บาท/กก.'},
 {name:'ทอดมันคุณอุ๊',img:'ทอดมันคุณอุ๊.webp',size:'500 กรัม',priceToon:0,priceAgent:'25 บาท/กก.'},
 {name:'โบโลน่า BKP',img:'โบโลน่า-bkp.webp',size:'450 กรัม',priceToon:0,priceAgent:'57 บาท/กก.'},
 {name:'ลูกชิ้นกุ้ง โอเด้ง',img:'ลูกชิ้นกุ้ง-โอเด้ง.webp',size:'1 กก.',priceToon:0,priceAgent:'95 บาท/กก.'},
 {name:'ลูกชิ้นหมู 3 ตัว (กลมเล็ก)',img:'ลูกชิ้นหมู-3-ตัว-กลมเล็ก.webp',size:'1 กก.',priceToon:0,priceAgent:'86 บาท/กก.'},
 {name:'ลูกชิ้นหมู 3 ตัว (เม็ดขนุน)',img:'ลูกชิ้นหมู-3-ตัว-เม็ดขนุน.webp',size:'1 กก.',priceToon:0,priceAgent:'86 บาท/กก.'},
 {name:'เส้นตัด ซีเฟรช',img:'เส้นตัด-ซีเฟรช.webp',size:'1 กก.',priceToon:0,priceAgent:'60 บาท/กก.'},
 {name:'น้ำจิ้ม โอชา (ถุง)',img:'น้ำจิ้ม-โอชา-ถุง.webp',size:'1 กก.',priceToon:0,priceAgent:'24 บาท/กก.'},
 {name:'ฮอทดอกอีสาน แหลมทอง',img:'ฮอทดอกอีสาน-แหลมทอง.webp',size:'500 กรัม',priceToon:0,priceAgent:'67 บาท/แพ็ค.'},
 {name:'ไส้กรอกอีสาน พันธุ์แท้',img:'ไส้กรอกอีสาน-พันธุ์แท้.webp',size:'500 กรัม',priceToon:0,priceAgent:'62 บาท/แพ็ค.'},
 {name:'จ้อไก่ผสมปูอัด 5 เส้น',img:'เบทาโกร-จัมโบ้นม.webp',size:'500 กรัม',priceToon:0,priceAgent:'34 บาท/แพ็ค.'},
 {name:'น้ำจิ้ม โอชา (ขวดเล็ก)',img:'น้ำจิ้ม-โอชา-ขวดเล็ก.webp',size:'220 กรัม',priceToon:0,priceAgent:'11 บาท/ขวด'},
 {name:'ค็อกเทลตำนาน แหลมทอง',img:'ค็อกเทลตำนาน-แหลมทอง-real.webp',size:'1 กก.',priceToon:0,priceAgent:'56 บาท/กก.'},
 {name:'ค็อกเทลตำนาน แหลมทอง (เล็ก)',img:'ค็อกเทลตำนาน-แหลมทอง-เล็ก.webp',size:'500 กรัม',priceToon:0,priceAgent:'34 บาท/แพ็ค'},
 {name:'ค็อกเทลตำนาน แหลมทอง (แว็คคัม)',img:'ค็อกเทลตำนาน-แหลมทอง.webp',size:'1 กก.',priceToon:0,priceAgent:'57 บาท/กก.'},
 {name:'จัมโบ้แฟรงค์ แหลมทอง (26 ชิ้น)',img:'จัมโบ้แฟรงค์-แหลมทอง-26-ชิ้น.webp',size:'1 กก.',priceToon:0,priceAgent:'76 บาท/กก.'},
 {name:'ไส้กรอก ข้าวเหนียวไก่ย่าง AFM',img:'ไส้กรอก-ข้าวเหนียวไก่ย่าง-afm.webp',size:'1 กก.',priceToon:0,priceAgent:'62 บาท/กก.'},
 {name:'จัมโบ้ หมูอร่อย ผสมชีส AFM',img:'จัมโบ้-หมูอร่อย-ผสมชีส-afm.webp',size:'1 กก.',priceToon:0,priceAgent:'107 บาท/กก.'},
 {name:'จัมโบ้ หมูอร่อย วนิลา AFM',img:'จัมโบ้-หมูอร่อย-วนิลา-afm.webp',size:'1 กก.',priceToon:0,priceAgent:'100 บาท/กก.'},
 {name:'ไส้กรอก แวมไพร์ AFM',img:'ไส้กรอก-แวมไพร์-afm.webp',size:'1 กก.',priceToon:0,priceAgent:'118 บาท/กก.'},
 {name:'จัมโบ้ทอง สอดไส้ชีส AFM',img:'จัมโบ้ทอง-สอดไส้ชีส-afm.webp',size:'1 กก.',priceToon:0,priceAgent:'99 บาท/กก.'},
 {name:'ไส้กรอกชีสเนย AFM (22 ชิ้น)',img:'ไส้กรอกชีสเนย-afm-22-ชิ้น.webp',size:'1 กก.',priceToon:0,priceAgent:'82 บาท/กก.'},
 {name:'ไส้กรอกเวียนนา ไส้ชีส AFM',img:'ไส้กรอกเวียนนา-ไส้ชีส-afm.webp',size:'1 กก.',priceToon:0,priceAgent:'112 บาท/กก.'},
 {name:'ไส้กรอกเวียนนา ไส้ชีส AFM (เล็ก)',img:'ไส้กรอกเวียนนา-ไส้ชีส-afm-เล็ก.webp',size:'500 กรัม',priceToon:0,priceAgent:'58 บาท/แพ็ค'},
 {name:'แฟรงค์ไก่ทองคำ หนังกรอบ AFM',img:'แฟรงค์ไก่ทองคำ-หนังกรอบ-afm.webp',size:'1 กก.',priceToon:0,priceAgent:'87 บาท/กก.'},
 {name:'ฮอทดอก ซุปเปอร์ชีส แหลมทอง',img:'ฮอทดอก-ซุปเปอร์ชีส-แหลมทอง.webp',size:'600 กรัม',priceToon:0,priceAgent:'55 บาท/แพ็ค'},
 {name:'วิวไก่ทอง jpm',img:'วิวไก่ทอง-jpm.webp',size:'1 กก.',priceToon:0,priceAgent:'81 บาท/กก.'},
 {name:'ไส้กรอก ซุปเปอร์พรีเมี่ยม AFM',img:'ไส้กรอก-ซุปเปอร์พรีเมี่ยม-afm.webp',size:'1 กก.',priceToon:0,priceAgent:'84 บาท/กก.'},
 {name:'ค็อกเทลไก่ บีฟู้ด',img:'ค็อกเทลไก่-บีฟู้ด.webp',size:'1 กก.',priceToon:0,priceAgent:'48 บาท/กก.'},
 {name:'ไส้กรอกหมูอร่อย',img:'ไส้กรอกหมูอร่อย.webp',size:'500 กรัม',priceToon:0,priceAgent:'58'},
 {name:'ไก่จัมโบ้รมควัน เล็กโคราช (26 ชิ้น)',img:'ไก่จัมโบ้รมควัน-เล็กโคราช-26-ชิ้น.webp',size:'1 กก.',priceToon:0,priceAgent:'72 บาท/แพ็ค'},
 {name:'ขนมจีบ ท่าฉลอม',img:'ขนมจีบ-ท่าฉลอม.webp',size:'450 กรัม',priceToon:0,priceAgent:'55 บาท/แพ็ค'},
 {name:'เฟรนฟราย 7 มิล วีนัส ( ถุง 2 โล )',img:'เฟรนฟราย-7-มิล-วีนัส-ถุง-2-โล.webp',size:'2 กก.',priceToon:0,priceAgent:'138 บาท/แพ็ค'},
 {name:'หมึกทูป 5/6',img:'หมึกทูป-5-6.webp',size:'1 กก.',priceToon:0,priceAgent:'38 บาท/กก.'},
 {name:'หมึกทูป 2/3',img:'หมึกทูป-2-3.webp',size:'1 กก.',priceToon:0,priceAgent:'78 บาท/กก.'},
 {name:'เศษเป็ด เบญจวรรณ',img:'เศษเป็ด-เบญจวรรณ.webp',size:'1 กก.',priceToon:0,priceAgent:'90 บาท/กก.'},
 {name:'หมูยอแผ่นใหญ่ เล็กโคราช',img:'หมูยอแผ่นใหญ่-เล็กโคราช.webp',size:'900 กรัม',priceToon:0,priceAgent:'68 บาท/แพ็ค'},
 {name:'จ๊อสาหร่าย 6 เส้น',img:'จ๊อสาหร่าย-6-เส้น.webp',size:'500 กรัม',priceToon:0,priceAgent:'40 บาท/แพ็ค'},
 {name:'ฮอทดอกอีสาน',img:'ฮอทดอกอีสาน.webp',size:'500 กรัม',priceToon:0,priceAgent:'58 บาท/แพ็ค'},
 {name:'ไส้กรอกจัมโบ้ทอง AFM',img:'ไส้กรอกจัมโบ้ทอง-afm.webp',size:'1 กก.',priceToon:0,priceAgent:'82 บาท/กก.'},
 {name:'จัมโบ้โคราช 14 ชิ้น',img:'จัมโบ้โคราช-14-ชิ้น.webp',size:'1 กก.',priceToon:0,priceAgent:'72 บาท/กก.'},
 {name:'ลูกชิ้นปลาอาทร สาหร่าย',img:'ลูกชิ้นปลาอาทร-สาหร่าย.webp',size:'1 กก.',priceToon:0,priceAgent:'49 บาท/กก.'},
 {name:'ลูกชิ้นแฟนซี เบทาโกร',img:'ลูกชิ้นแฟนซี-เบทาโกร.webp',size:'1 กก.',priceToon:0,priceAgent:'67 บาท/กก.'},
 {name:'เบทาโกร จัมโบ้โกดล์ 14 ชิ้น',img:'เบทาโกร-จัมโบ้โกดล์-14-ชิ้น.webp',size:'1 กก.',priceToon:0,priceAgent:'77 บาท/กก.'},
 {name:'ไส้กรอก สโมกกี้ไบท์ AFM',img:'ไส้กรอก-สโมกกี้ไบท์-afm.webp',size:'1 กก.',priceToon:0,priceAgent:'73 บาท/กก.'},
 
 
        /* 30% */ {name:'pp ไส้กรอกบิ๊กชีส',priceAgent: '88 บาท/กก',price: '90 บาท/กก',img:'pp-ไส้กรอกบิ๊กชีส.jpg',size:'1 กก',priceToon:'79'},
        /* 30% */ {name:'pp หนังกรอบทอง',priceAgent: '23 บาท/แพ็ค',price: '30 บาท/แพ็ค',img:'pp-หนังกรอบทอง.jpg',size:'250 กรัม',priceToon:'19'},
        /* 30% */ {name:'pp หนังกรอบสาหร่าย',priceAgent: '23 บาท/แพ็ค',price: '30 บาท/แพ็ค',img:'pp-หนังกรอบสาหร่าย.jpg',size:'250 กรัม',priceToon:'19'},
        /* 30% */ {name:'pp หนังกรอบสาหร่าย ใหญ่',priceAgent: '80 บาท/กก.',price: '90 บาท/แพ็ค',img:'หนังกรอบสาหร่าย-pp-ใหญ่.webp',size:'1 กก.',priceToon:'19'},
        {name:'คิดดี้แครบ ทวีวงษ์',priceAgent: '61/แพ็ค',price: '65/แพ็ค',img:'คิดดี้แครบ-ทวีวงษ์.jpg',size:'500 กรัม',priceToon:''},
        {name:'ปูอัดจัมโบ้ ทวีวงษ์',priceAgent: '52/แพ็ค',price: '55/แพ็ค',img:'ปูอัดจัมโบ้-ทวีวงษ์.jpg',size:'500 กรัม',priceToon:''},
        //{name:'ปูอัดจัมโบ้ ทาอิจิ (250 กรัม)',priceAgent: '28/แพ็ค',price: '35/แพ็ค',img:'ปูอัดจัมโบ้-ทาอิจิ-270-กรัม.jpg',size:'270 กรัม',priceToon:''},
        //{name:'ลูกชิ้นปลาบู๊ฮวดถุงม่วง (แบน)',priceAgent: '135/กก.',price: '140/กก.',img:'ลูกชิ้นปลาบู๊ฮวดถุงม่วง-แบน.jpg',size:'500 กรัม',priceToon:''},
        //{name:'ลูกชิ้นปลาบู๊ฮวดถุงม่วง (แหลม)',priceAgent: '135/กก.',price: '140/กก.',img:'ลูกชิ้นปลาบู๊ฮวดถุงม่วง-แหลม.jpg',size:'500 กรัม',priceToon:''},
        //{name:'ลูกชิ้นหมู แม่พลอยบุญ',priceAgent: '65/กก.',price: '75/กก.',img:'ลูกชิ้นหมู-แม่พลอยบุญ.jpg',size:'1 กก.',priceToon:''},
        //{name:'ไส้กรอกอีสาน jpm',priceAgent: '85/แพ็ค',price: '90/แพ็ค',img:'ไส้กรอกอีสาน-jpm.jpg',size:'900 กรัม',priceToon:''},
        {name:'ไส้อั่วสมุนไพรไก่ มินิ',priceAgent: '35/แพ็ค',price: '37/แพ็ค',img:'ไส้อั่วสมุนไพรไก่-มินิ.jpg',size:'250 กรัม',priceToon:''},
        {name:'หมูเด้งหลอด',priceAgent: '13/หลอด',price: '15/หลอด',img:'หมูเด้งหลอด.jpg',size:'135 กรัม',priceToon:''},
        //{name:'แหนมคอไก่ แม่พลอย',priceAgent: '29/แพ็ค',price: '35/แพ็ค',img:'แหนมคอไก่-แม่พลอย.jpg',size:'500 กรัม',priceToon:''},
        {name:'แหนมดอนเมือง-แพ็ค-5-แท่ง',priceAgent: '48/แพ็ค',price: '55/แพ็ค',img:'แหนมดอนเมือง-แพ็ค-5-แท่ง.jpg',size:'270 กรัม',priceToon:''},
        //{name:'แหนมซี่โครงไก่อ่อน แม่พลอยบุญ',priceAgent: '29/แพ็ค',price: '35/แพ็ค',img:'แหนมซี่โครงไก่อ่อน-แม่พลอยบุญ.jpg',size:'400 กรัม',priceToon:''},
        //{name:'แหนมปลายปีกไก่ แม่พลอยบุญ',priceAgent: '29/แพ็ค',price: '35/แพ็ค',img:'แหนมปลายปีกไก่-แม่พลอยบุญ.jpg',size:'300 กรัม',priceToon:''},
        //{name:'pbm มากิด็อก แพ็ค 5 ชิ้น',priceAgent: '28/แพ็ค',price: '35/แพ็ค',img:'pbm-มากิด็อก-แพ็ค-5-ชิ้น.jpg',size:'150 กรัม',priceToon:''},
        //{name:'pbm-ไส้กรอกชีสวนิลา',priceAgent: '70/กก.',price: '79/กก.',img:'pbm-ไส้กรอกชีสวนิลา.jpg',size:'1 กก.',priceToon:''},
        {name:'ไก่จ๊อ sp',priceAgent: '73/กก.',price: '80/กก.',img:'ไก่จ๊อ-sp.jpg',size:'1 กก.',priceToon:'65'},
        {name:'แซนวิชแฮมไก่ JPM',priceAgent: '31/แพ็ค',price: '35/แพ็ค',img:'แซนวิชแฮมไก่.jpg',size:'250 กรัม',priceToon:'24'},
        {name:'ปลาซองบู๊ฮวด ถุงเขียว',priceAgent: '47/กก.',price: '55/กก.',img:'ปลาซองบู๊ฮวด-ถุงเขียว.jpg',size:'200 กรัม',priceToon:'40'},
        {name:'ปลาตาหวาน เกรด A แบน',priceAgent: '62/กก.',price: '65/กก.',img:'ปลาตาหวาน-เกรด-a-แบน.jpg',size:'500 กรัม',priceToon:'50'},
        {name:'ปลาตาหวาน เกรด A แหลม',priceAgent: '62/กก.',price: '65/กก.',img:'ปลาตาหวาน-เกรด-a-แหลม.jpg',size:'500 กรัม',priceToon:'50'},
        {name:'ปลาตาหวาน เกรด B กลม',priceAgent: '58/กก.',price: '60/กก.',img:'ปลาตาหวาน-เกรด-b-กลม.jpg',size:'500 กรัม',priceToon:'40'},
        {name:'ปลาตาหวาน เกรด B แบน',priceAgent: '58/กก.',price: '60/กก.',img:'ปลาตาหวาน-เกรด-b-แบน.jpg',size:'500 กรัม',priceToon:'40'},
        {name:'ปลาเส้น sp แพ็ค 1 โล',priceAgent: '75/กก.',price: '85/กก.',img:'ปลาเส้น-sp-แพ็ค-1-โล.jpg',size:'1 กก.',priceToon:'65'},
        {name:'ลูกชิ้นปลาตั้งเจริญ',priceAgent: '52/กก.',price: '60/กก.',img:'ลูกชิ้นปลาตั้งเจริญ.jpg',size:'250 กรัม',priceToon:'42'},
        {name:'ลูกชิ้นเต้าหู้ปูอลาสก้า',priceAgent: '80/กก.',price: '85/กก.',img:'ลูกชิ้นเต้าหู้ปูอลาสก้า.jpg',size:'500 กรัม',priceToon:'66'},
        {name:'ขาไก่',priceAgent: '70/กก.',price: '75/กก.',img:'ขาไก่.jpg',size:'1 กก.',priceToon:'65'},
        {name:'ลูกชิ้นหมูพรชัย',priceAgent: '65/กก.',price: '80/กก.',img:'ลูกชิ้นหมูพรชัย.jpg',size:' 1 กก.',priceToon:'53'},
        // กกก
        //{name:'กระดูกอ่อนใบพาย',priceAgent: '95/แพ็ค',price: '110/แพ็ค',img:'กระดูกอ่อนใบพาย.jpg',size:'300 กรัม',priceToon:'no set'},
        {name:'ขนมจีบปู เยาวราช',priceAgent: '76/กก.',price: '89/กก.',img:'ขนมจีบปู-เยาวราช.jpg',size:'500 กรัม',priceToon:'no set'},
        {name:'จัมโบ้ชีส pp',priceAgent: '88 บาท/กก.',price: '92 บาท/กก.',img:'จัมโบ้ชีส-pp.jpg',size:'1 กก.',priceToon:'no set'},
        {name:'ซูซูฟิชเค้ก ทวีวงษ์',priceAgent: '62 บาท/แพ็ค.',price: '69 บาท/แพ็ค',img:'ซูซูฟิชเค้ก-ทวีวงษ์.jpg',size:'500 กรัม',priceToon:'no set'},
        //{name:'เต้าหู้ไข่ไก่',priceAgent: '5 บาท/หลอด',price: '7 บาท/หลอด',img:'เต้าหู้ไข่ไก่.jpg',size:'120 กรัม',priceToon:'no set'},
        {name:'ทอดมันปลากราย จิตร',priceAgent: '70 บาท/กก.',price: '78 บาท/กก.',img:'ทอดมันปลากราย-จิตร.jpg',size:'500 กรัม',priceToon:'no set'},
        //{name:'นักเก็ตปลา',priceAgent: '68 บาท/แพ็ค',price: '75 บาท/แพ็ค',img:'นักเก็ตปลา.jpg',size:'500 กรัม',priceToon:'no set'},
        //{name:'ปลาทูหวาน',priceAgent: '55 บาท/แพ็ค',price: '65 บาท/แพ็ค',img:'ปลาทูหวาน.jpg',size:'300 กรัม',priceToon:'no set'},
        {name:'ปลาระเบิดจีจี (กลม)',priceAgent: '40 บาท/กก.',price: '49 บาท/กก.',img:'ปลาระเบิดจีจี-กลม.jpg',size:'1 กก.',priceToon:'no set'},
        {name:'ปลาระเบิดจีจี (กลมแดง)',priceAgent: '40 บาท/กก.',price: '49 บาท/กก.',img:'ปลาระเบิดจีจี-กลมแดง.jpg',size:'1 กก.',priceToon:'no set'},
        {name:'ปลาระเบิดจีจี (แหลม)',priceAgent: '40 บาท/กก.',price: '49 บาท/กก.',img:'ปลาระเบิดจีจี-แหลม.jpg',size:'1 กก.',priceToon:'no set'},
        {name:'ปลาเส้น เยาวราช',priceAgent: '111 บาท/กก.',price: '125 บาท/กก.',img:'ปลาเส้น-เยาวราช.jpg',size:'1 กก.',priceToon:'no set'},
        {name:'ปลาหมึกกรอบ',priceAgent: '65 บาท/กก.',price: '80 บาท/กก.',img:'ปลาหมึกกรอบ.jpg',size:'5 กก.',priceToon:'no set'},
        {name:'ปลาหมึกไข่สวรรค์',priceAgent: '75 บาท/แพ็ค',price: '80 บาท/แพ็ค',img:'ปลาหมึกไข่สวรรค์.jpg',size:'300 กรัม',priceToon:'no set'},
        {name:'ปูหลอด ทวีวงษ์',priceAgent: '60 บาท/แพ็ค',price: '69 บาท/แพ็ค',img:'ปูหลอด-ทวีวงษ์.jpg',size:'500 กรัม',priceToon:'no set'},
        {name:'ปูอัด เกรดเอ',priceAgent: '45 บาท/แพ็ค',price: '52 บาท/แพ็ค',img:'ปูอัด-เกรดเอ.jpg',size:'500 กรัม',priceToon:'no set'},
        {name:'ฟิชดัมเบลล์ ทวีวงษ์',priceAgent: '69 บาท/แพ็ค',price: '75 บาท/แพ็ค',img:'ฟิชดัมเบลล์-ทวีวงษ์.jpg',size:'500 กรัม',priceToon:'no set'},
        {name:'แมงกระพรุนจืด ถุงโล',priceAgent: '100 บาท/กก.',price: '100 บาท/กก.',img:'แมงกระพรุนจืด-ถุงโล.webp',size:'5 กก.',priceToon:'no set'},
        {name:'ลูกชิ้นเนื้อพรชัย',priceAgent: '65 บาท/กก.',price: '79 บาท/กก.',img:'ลูกชิ้นเนื้อพรชัย.jpg',size:'1 กก.',priceToon:'no set'},
        {name:'ลูกชิ้นปลาแกง บู๊ฮวด',priceAgent: '57 บาท/กก.',price: '60 บาท/กก.',img:'ลูกชิ้นปลาแกง-บู๊ฮวด.jpg',size:'500 กรัม',priceToon:'no set'},
        {name:'ลูกชิ้นปลา-ตั้งเจริญ',priceAgent: '52 บาท/กก.',price: '58 บาท/กก.',img:'ลูกชิ้นปลา-ตั้งเจริญ.jpg',size:'500 กรัม',priceToon:'no set'},
        {name:'ลูกชิ้นปลาบุญพงษ์ แหลม',priceAgent: '117 บาท/กก.',price: '125 บาท/กก.',img:'ลูกชิ้นปลาบุญพงษ์-แหลม.jpg',size:'1 กก.',priceToon:'no set'},
        {name:'ลูกชิ้นปู 9 ดาว',priceAgent: '55 บาท/กก.',price: '65 บาท/กก.',img:'ลูกชิ้นปู-9-ดาว.jpg',size:'500 กรัม / 1 กก.',priceToon:'no set'},
        {name:'ลูกชิ้นหมูพรชัย เกรดเอ',priceAgent: '95 บาท/กก.',price: '110 บาท/กก.',img:'ลูกชิ้นหมูพรชัย-เกรดเอ.jpg',size:'1 กก.',priceToon:'no set'},
        {name:'ลูกชิ้นเอ็นหมูพรชัย',priceAgent: '70 บาท/กก.',price: '78 บาท/กก.',img:'ลูกชิ้นเอ็นหมูพรชัย.jpg',size:'1 กก.',priceToon:'no set'},
        {name:'เส้นตัด-ซีเฟรช',priceAgent: '60 บาท/กก.',price: '75 บาท/กก.',img:'เส้นตัด-ซีเฟรช.jpg',size:'1 กก.',priceToon:'no set'},
        {name:'หมึกบั้ง',priceAgent: '55 บาท/กก.',price: '65 บาท/กก.',img:'หมึกบั้ง.jpg',size:'1 กก.',priceToon:'no set'},
        {name:'หมูปิ้ง แพ็คใหญ่',priceAgent: '3.2 บาท/ไม้',price: '8 บาท/ไม้',img:'หมูปิ้ง.jpg',size:'1 ไม้',priceToon:'no set'},
        {name:'หอยจ๊อวันเพ็ญ',priceAgent: '80 บาท/กก.',price: '85 บาท/กก.',img:'หอยจ๊อวันเพ็ญ.jpg',size:'1 กก.',priceToon:'no set'},
        {name:'แอลพีเอฟ แหลมทอง',priceAgent: '58 บาท/กก.',price: '65 บาท/กก.',img:'แอลพีเอฟ-แหลมทอง.jpg',size:'1 กก.',priceToon:'no set'},
        /* 30% */ {name:'จับโบ้รมควัน แหลมทอง 14 ชิ้น',priceAgent: '75 บาท/กก.',price: '85 บาท/กก.',img:'จับโบ้รมควันแหลมทอง14ชิ้น.jpg',size:'1 กก.',priceToon:'0'},
        /* 30% */ {name:'ลูกชิ้นปลาบู๊ฮวด (แบน) จัมโบ้',priceAgent: '87 บาท/กก.',price: '89 บาท/กก.',img:'บู๊ฮวดแบนจัมโบ้.jpg',size:'1 กก.',priceToon:'0'},
        /* 30% */ {name:'ไส้อั่วหนังกรอบ 40 ชิ้น',priceAgent: '80 บาท/กก.',price: '88 บาท/กก.',img:'ไส้อั่วหนังกรอบ40ชิ้น.jpg',size:'1 กก.',priceToon:'0'},
        /* 30% */ {name:'ไส้อั่วเชียงใหม่',priceAgent: '76 บาท/กก.',price: '85 บาท/กก.',img:'ไส้อั่วเชียงใหม่.jpg',size:'1 กก.',priceToon:'0'},
        /* 30% */ {name:'ลูกชิ้นเนื้อ พรชัย',priceAgent: '65 บาท/กก.',price: '79 บาท/กก.',img:'ลูกชิ้นเนื้อ-พรชัย.jpg',size:'1 กก.',priceToon:'0'},
        /* 30% */ {name:'จับโบ้รมควัน แหลมทอง 13 ชิ้น',priceAgent: '75 บาท/กก.',price: '85 บาท/กก.',img:'จับโบ้รมควันแหลมทอง13ชิ้น.jpg',size:'1 กก.',priceToon:'0'},
        /* 30% */ {name:'ลูกชิ้นหมูขายดี',priceAgent: '60 บาท/กก.',price: '67 บาท/กก.',img:'ลูกชิ้นหมูขายดี.jpg',size:'1 กก.',priceToon:'0'},
        /* 30% */ {name:'เนื้อปลากรายเด้ง',priceAgent: '76 บาท/กก.',price: '86 บาท/กก.',img:'เนื้อปลากรายเด้ง.jpg',size:'1 กก.',priceToon:'0'},
        /* 30% */ {name:'ลูกชิ้นปลาบู๊ฮวดถุงส้ม ( แหลม )',priceAgent: '77 บาท/กก.',price: '79 บาท/กก.',img:'ลูกชิ้นปลาบู๊ฮวดถุงส้ม-แหลม.jpg',size:'1 กก.',priceToon:'0'},
        /* 30% */ {name:'จับโบ้อีสาน แหลมทอง',priceAgent: '120 บาท/กก.',price: '130 บาท/กก.',img:'จับโบ้อีสานแหลมทอง.jpg',size:'1 กก.',priceToon:'0'},
        /* 30% */ {name:'แฟรงค์ไก่ แหลมทอง',priceAgent: '82 บาท/กก.',price: '90 บาท/กก.',img:'แฟรงค์ไก่แหลมทอง.jpg',size:'1 กก.',priceToon:'0'},
        /* 30% */ {name:'ลูกชิ้นเอ็นเนื้อ โกเด้ง',priceAgent: '205 บาท/กก.',price: '215 บาท/กก.',img:'ลูกชิ้นเอ็นเนื้อ-โกเด้ง.jpg',size:'1 กก.',priceToon:'0'},
        /* 30% */ {name:'ลูกชิ้นหมู โฮเด้ง',priceAgent: '205 บาท/กก.',price: '215 บาท/กก.',img:'ลูกชิ้นหมู-โฮเด้ง.jpg',size:'1 กก.',priceToon:'0'},
        /* 30% */ {name:'ขนมจีบหมู',priceAgent: '35 บาท/แพ็ค',price: '40 บาท/แพ็ค',img:'ขนมจีบหมู.jpg',size:'320 กรัม',priceToon:'0'},
        /* 30% */ {name:'ขนมจีบกุ้ง',priceAgent: '35 บาท/แพ็ค',price: '40 บาท/แพ็ค',img:'ขนมจีบกุ้ง.jpg',size:'320 กรัม',priceToon:'0'},
        /* 30% */ {name:'ปลาเส้นต้ม ส มหาชัย',priceAgent: '60 บาท/แพ็ค',price: '65 บาท/แพ็ค',img:'ปลาเส้นต้ม-ส-มหาชัย.jpg',size:'950 กรัม',priceToon:'0'},
        /* 30% */ {name:'ปลาเส้นทอด ส มหาชัย',priceAgent: '60 บาท/แพ็ค',price: '65 บาท/แพ็ค',img:'ปลาเส้นทอด-ส-มหาชัย.jpg',size:'950 กรัม',priceToon:'0'},
        /* 30% */ {name:'ลูกชิ้นปลาเอ 5 ดาว (กลม)',priceAgent: '67 บาท/กก.',price: '73 บาท/กก.',img:'ลูกชิ้นปลาเอ-5-ดาว-กลม.jpg',size:'1 กก.',priceToon:'0'},
        /* 30% */ {name:'ไส้กรอกอีสานเสียบไม้',priceAgent: '77 บาท/แพ็ค',price: '86 บาท/แพ็ค',img:'ไส้กรอกอีสานเสียบไม้.jpg',size:'1 กก.',priceToon:'0'},
        /* 30% */ {name:'แหนมหมูนิตยา',priceAgent: '74 บาท/แพ็ค',price: '82 บาท/แพ็ค',img:'แหนมหมูนิตยา.jpg',size:'900 กรัม',priceToon:'0'},
        /* 30% */ {name:'แหนมปลากรายนิตยา',priceAgent: '77 บาท/แพ็ค',price: '82 บาท/แพ็ค',img:'แหนมปลากรายนิตยา.jpg',size:'900 กรัม',priceToon:'0'},
        {name:'หมูยออุบล PN แท่งใหญ่',priceAgent: '31 บาท/แท่ง',price: '33 บาท/แท่ง',img:'หมูยออุบล-pn-แท่งใหญ่.webp',size:'450 กรัม',priceToon:'0'},
        {name:'หมึกบั้ง เเฮปปี้ (ถุงเหลือง)',priceAgent: '39 บาท/กก.',price: '42 บาท/กก.',img:'หมึกบั้ง-เเฮปปี้-ถุงเหลือง.webp',size:'1 กก.',priceToon:'0'},
        {name:'หมึกบั้ง เเฮปปี้ (ถุงแดง)',priceAgent: '49 บาท/กก.',price: '52 บาท/กก.',img:'หมึกบั้ง-เเฮปปี้-ถุงแดง.webp',size:'1 กก.',priceToon:'0'},
        {name:'ปลาหมึกหั่นเเท่ง',priceAgent: '50 บาท/กก.',price: '53 บาท/กก.',img:'ปลาหมึกหั่นเเท่ง.webp',size:'1 กก.',priceToon:'0'},
        {name:'ทะเลรวม',priceAgent: '70 บาท/กก.',price: '74 บาท/กก.',img:'ทะเลรวม.webp',size:'1 กก.',priceToon:'0'},
        {name:'เนื้อหมึกหั่นเต๋า jaja',priceAgent: '49 บาท/กก.',price: '52 บาท/กก.',img:'เนื้อหมึกหั่นเต๋า-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'หมึกวงหนัง jaja',priceAgent: '59 บาท/กก.',price: '62 บาท/กก.',img:'หมึกวงหนัง-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'หมึกวงขาว',priceAgent: '64 บาท/กก.',price: '68 บาท/กก.',img:'หมึกวงขาว.webp',size:'1 กก.',priceToon:'0'},
        {name:'หัวหมึกผ่า ไอคิวเอฟ',priceAgent: '49 บาท/กก.',price: '53 บาท/กก.',img:'หัวหมึกผ่า-ไอคิวเอฟ.webp',size:'1 กก.',priceToon:'0'},
        {name:'ดอลลี่หั่นไม่ติดท้อง jaja',priceAgent: '50 บาท/กก.',price: '53 บาท/กก.',img:'ดอลลี่หั่นไม่ติดท้อง-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'เนื้อนางรม',priceAgent: '115 บาท/กก.',price: '120 บาท/กก.',img:'เนื้อนางรม.webp',size:'1 กก.',priceToon:'0'},
        {name:'หอยแมลงภู่ต้ม',priceAgent: '92 บาท/กก.',price: '98 บาท/กก.',img:'หอยแมลงภู่ต้ม.webp',size:'1 กก.',priceToon:'0'},
        {name:'พวงไข่อ่อน kcf',priceAgent: '122 บาท/กก.',price: '130 บาท/กก.',img:'พวงไข่อ่อน-kcf.webp',size:'1 กก.',priceToon:'0'},
        {name:'หมึกดอกไม้ jaja',priceAgent: '60 บาท/กก.',price: '65 บาท/กก.',img:'หมึกดอกไม้-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'เนื้อเซลม่อน เซนโดะ',priceAgent: '150 บาท/แพ็ค',price: '160 บาท/แพ็ค',img:'เนื้อเซลม่อน-เซนโดะ.webp',size:'350 กรัม',priceToon:'0'},
        {name:'ปลาหมึกยัดไส้',priceAgent: '90 บาท/กก.',price: '95 บาท/กก.',img:'ปลาหมึกยัดไส้.webp',size:'1 กก.',priceToon:'0'},
        {name:'หอยลายเปลือก 100/200',priceAgent: '50 บาท/กก.',price: '55 บาท/กก.',img:'หอยลายเปลือก-100-200.webp',size:'1 กก.',priceToon:'0'},
        {name:'แซลม่อนสเต็ก เกรด A 4/5',priceAgent: '285 บาท/กก.',price: '300 บาท/กก.',img:'แซลม่อนสเต็ก-เกรด-a-4-5.webp',size:'1 กก.',priceToon:'0'},
        {name:'แซลม่อนสเต็ก เกรด A 6/8',priceAgent: '280 บาท/กก.',price: '295 บาท/กก.',img:'แซลม่อนสเต็ก-เกรด-a-6-8.webp',size:'1 กก.',priceToon:'0'},
        {name:'ปลายหนวดปลาหมึก ไซส์ 7/9 jaja',priceAgent: '75 บาท/กก.',price: '80 บาท/กก.',img:'ปลายหนวดปลาหมึก-ไซส์-7-9-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'ปลายหนวดปลาหมึก ไซส์ 15/18 jaja',priceAgent: '75 บาท/กก.',price: '80 บาท/กก.',img:'ปลายหนวดปลาหมึก-ไซส์-15-18-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'ปูตัดแต่งกล่องฟ้า เดม่อนฟิช ไซส์ 2s 31/40',priceAgent: '180 บาท/กก.',price: '195 บาท/กก.',img:'ปูตัดแต่งกล่องฟ้า-เดม่อนฟิช-ไซส์-2s-31-40.webp',size:'1 กก.',priceToon:'0'},
        {name:'ไข่ขาวส้ม',priceAgent: '133 บาท/กก.',price: '140 บาท/กก.',img:'ไข่ขาวส้ม.webp',size:'1 กก.',priceToon:'0'},
        {name:'ไข่อาเจน',priceAgent: '180 บาท/กก.',price: '190 บาท/กก.',img:'ไข่อาเจน.webp',size:'1 กก.',priceToon:'0'},
        {name:'หอยเชลไทย เทปเขียว แพ็ค 25 ฝา',priceAgent: '80 บาท/แพ็ค',price: '85 บาท/แพ็ค',img:'หอยเชลไทย-เทปเขียว-แพ็ค-25-ฝา.webp',size:'25 ฝา',priceToon:'0'},
        {name:'หอยเชลไทย เทปน้ำเงิน แพ็ค 18 ฝา',priceAgent: '125 บาท/แพ็ค',price: '135 บาท/แพ็ค',img:'หอยเชลไทย-เทปน้ำเงิน-แพ็ค-18-ฝา.webp',size:'18 ฝา',priceToon:'0'},
        {name:'ปลานิลแล่เนื้อ',priceAgent: '65 บาท/กก.',price: '69 บาท/กก.',img:'ปลานิลแล่เนื้อ.webp',size:'1 กก.',priceToon:'0'},
        {name:'ซาบะแล่เนื้อ',priceAgent: '190 บาท/กก.',price: '205 บาท/กก.',img:'ซาบะแล่เนื้อ.webp',size:'1 กก.',priceToon:'0'},
        {name:'หมูหมักงา jaja',priceAgent: '70 บาท/กก.',price: '75 บาท/กก.',img:'หมูหมักงา-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'หมูหมักบาร์บีคิว jaja',priceAgent: '70 บาท/กก.',price: '75 บาท/กก.',img:'หมูหมักบาร์บีคิว-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'สามชั้นหมักงา jaja',priceAgent: '90 บาท/กก.',price: '95 บาท/กก.',img:'สามชั้นหมักงา-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'หมูหั่นแกง ผสมไก่',priceAgent: '70 บาท/กก.',price: '75 บาท/กก.',img:'หมูหั่นแกง-ผสมไก่.webp',size:'1 กก.',priceToon:'0'},
        {name:'ซี่โครงหมูน้ำปลา ฟาร์มดี',priceAgent: '72 บาท/แพ็ค',price: '76 บาท/แพ็ค',img:'ซี่โครงหมูน้ำปลา-ฟาร์มดี.webp',size:'700 กรัม',priceToon:'0'},
        {name:'ดอลลี่ตัวไม่ติดท้อง 3 ชิ้น',priceAgent: '70 บาท/กก.',price: '75 บาท/กก.',img:'ดอลลี่ตัวไม่ติดท้อง-3-ชิ้น.webp',size:'1 กก.',priceToon:'0'},
        {name:'ท้องแซลม่อน B jaja',priceAgent: '78 บาท/กก.',price: '84 บาท/กก.',img:'ท้องแซลม่อน-b-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'ท้องแซลม่อน A jaja',priceAgent: '130 บาท/กก.',price: '135 บาท/กก.',img:'ท้องแซลม่อน-a-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'ปลากะพงหั่นชิ้น ไซส์ 20/40',priceAgent: '165 บาท/กก.',price: '178 บาท/กก.',img:'ปลากะพงหั่นชิ้น-ไซส์-20-40.webp',size:'1 กก.',priceToon:'0'},
        {name:'ไส้ต้มเล็ก jaja',priceAgent: '120 บาท/กก.',price: '130 บาท/กก.',img:'ไส้ต้มเล็ก-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'ไส้ต้มใหญ่ jaja',priceAgent: '125 บาท/กก.',price: '135 บาท/กก.',img:'ไส้ต้มใหญ่-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'ปูนิ่ม ไซส์ 4 ตัวโล',priceAgent: '240 บาท/กก.',price: '260 บาท/กก.',img:'ปูนิ่ม-ไซส์-4-ตัวโล.webp',size:'1 กก.',priceToon:'0'},
        {name:'3 ผักรวม',priceAgent: '73 บาท/กก.',price: '78 บาท/กก.',img:'3-ผักรวม.webp',size:'1 กก.',priceToon:'0'},
        {name:'ถั่วแระญี่ปุ่น',priceAgent: '79 บาท/กก.',price: '84 บาท/กก.',img:'ถั่วแระญี่ปุ่น.webp',size:'1 กก.',priceToon:'0'},
        {name:'ผักโขม',priceAgent: '74 บาท/กก.',price: '79 บาท/กก.',img:'ผักโขม.webp',size:'1 กก.',priceToon:'0'},
        {name:'สตอเบอรี่',priceAgent: '85 บาท/กก.',price: '90 บาท/กก.',img:'สตอเบอรี่.webp',size:'1 กก.',priceToon:'0'},
        {name:'สันในไก่ jaja',priceAgent: '80 บาท/กก.',price: '85 บาท/กก.',img:'สันในไก่-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'สะโพกไก่ไร้กระดูก jaja',priceAgent: '73 บาท/กก.',price: '79 บาท/กก.',img:'สะโพกไก่ไร้กระดูก-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'ปีกบนไก่ jaja',priceAgent: '70 บาท/กก.',price: '75 บาท/กก.',img:'ปีกบนไก่-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'น่องใหญ่ jaja',priceAgent: '65 บาท/กก.',price: '70 บาท/กก.',img:'น่องใหญ่-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'น่องไก่สด ซันวาเล่',priceAgent: '65 บาท/กก.',price: '70 บาท/กก.',img:'น่องไก่สด-ซันวาเล่.webp',size:'1 กก.',priceToon:'0'},
        {name:'เศษไก่ jaja',priceAgent: '67 บาท/กก.',price: '72 บาท/กก.',img:'เศษไก่-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'แฮมไก่ (ถุงใส)',priceAgent: '80 บาท/กก.',price: '85 บาท/กก.',img:'แฮมไก่ถุงใส.webp',size:'1 กก.',priceToon:'0'},
        {name:'เศษเป็ด อัสมา',priceAgent: '90 บาท/กก.',price: '96 บาท/กก.',img:'เศษเป็ด-อัสมา.webp',size:'1 กก.',priceToon:'0'},
        {name:'หอยชิลี ไซส์ L jaja',priceAgent: '80 บาท/กก.',price: '90 บาท/กก.',img:'หอยชิลี-ไซส์-l-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'เนื้อชิลี ไซส์ 100/200 jaja',priceAgent: '80 บาท/กก.',price: '90 บาท/กก.',img:'เนื้อชิลี-ไซส์-100-200-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'เนื้อหอยลาย ไซส์ 700/1000 jaja',priceAgent: '95 บาท/กก.',price: '103 บาท/กก.',img:'เนื้อหอยลาย-ไซส์-700-1000-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'เนื้อหอยแครง ไซส์ 300/500 jaja',priceAgent: '118 บาท/กก.',price: '125 บาท/กก.',img:'เนื้อหอยแครง-ไซส์-300-500-jaja.webp',size:'1 กก.',priceToon:'0'},
        {name:'กุ้งพันอ้อย แม่วรรณา',priceAgent: '75 บาท/กก.',price: '80 บาท/กก.',img:'กุ้งพันอ้อย-แม่วรรณา.webp',size:'1 กก.',priceToon:'0'},
        {name:'กุ้ง​แม่น้ำ​ ไซส์ 6/8 Mat Chef',priceAgent: '520 บาท/กก.',price: '570 บาท/กก.',img:'กุ้ง_แม่น้ำ_-ไซส์-6-8-Mat-s-chef.webp',size:'1 กก.',priceToon:'0'},
        {name:'กุ้งต้มเเกะไว้หาง ไซส์ 26/30',priceAgent: '190 บาท/แพ็ค',price: '205 บาท/แพ็ค',img:'กุ้งต้มเเกะไว้หาง-ไซส์-26-30.webp',size:'500 กรัม',priceToon:'0'},
        {name:'เนื้อปูก้อน',priceAgent: '850 บาท/แพ็ค',price: '890 บาท/แพ็ค',img:'เนื้อปูก้อน.webp',size:'300 กรัม',priceToon:'0'},
        {name:'หอยหวาน maxima ไซส์ 50/80',priceAgent: '85 บาท/กก.',price: '90 บาท/กก.',img:'หอยหวาน-maxima-ไซส์-50-80.webp',size:'1 กก.',priceToon:'0'},
        {name:'หอยหลอด ไซส์ 8/10',priceAgent: '44 บาท/กก.',price: '48 บาท/กก.',img:'หอยหลอด-ไซส์-8-10.webp',size:'1 กก.',priceToon:'0'},
        {name:'หมูยอแม่วรรณา',priceAgent: '15 บาท/แท่ง',price: '16 บาท/แท่ง',img:'หมูยอแม่วรรณา.webp',size:'200 กรัม',priceToon:'0'},
        {name:'เฟรนฟรายเส้นหยัก โกลเด้น ฟริทโต้',priceAgent: '73 บาท/กก.',price: '78 บาท/กก.',img:'เฟรนฟรายเส้นหยัก-โกลเด้น-ฟริทโต้.webp',size:'1 กก.',priceToon:'0'},
        {name:'ปูอัด อลาสก้า',priceAgent: '75 บาท/กก.',price: '79 บาท/กก.',img:'ปูอัด-อลาสก้า.webp',size:'1 กก.',priceToon:'0'},
        {name:'เบค่อน ป้ายเหลือง',priceAgent: '150 บาท/แพ็ค',price: '163 บาท/แพ็ค',img:'เบค่อน-ป้ายเหลือง.webp',size:'700 กรัม',priceToon:'0'},
        {name:'เศษเบค่อน ป้ายเขียว',priceAgent: '123 บาท/กก.',price: '135 บาท/กก.',img:'เศษเบค่อน-ป้ายเขียว.webp',size:'1 กก.',priceToon:'0'},
        {name:'ตับหั่น ตับจ๋า',priceAgent: '80 บาท/กก.',price: '83 บาท/กก.',img:'ตับหั่น-ตับจ๋า.webp',size:'1 กก.',priceToon:'0'},
        {name:'หนังไก่ 456',priceAgent: '32 บาท/กก.',price: '39 บาท/กก.',img:'หนังไก่-456.webp',size:'1 กก.',priceToon:'0'},
        {name:'เอ็นเต้ไก่',priceAgent: '67 บาท/กก.',price: '72 บาท/กก.',img:'เอ็นเต้ไก่.webp',size:'1 กก.',priceToon:'0'},
        {name:'นักเก็ตไก่ ปันสุข',priceAgent: '99 บาท/กก.',price: '110 บาท/กก.',img:'นักเก็ตไก่-ปันสุข.webp',size:'1 กก.',priceToon:'0'},
        {name:'นักเก็ตไก่ ตาโต',priceAgent: '107 บาท/กก.',price: '115 บาท/กก.',img:'นักเก็ตไก่-ตาโต.webp',size:'1 กก.',priceToon:'0'},
        {name:'เอ็นข้อไก่ sp',priceAgent: '140 บาท/กก.',price: '150 บาท/กก.',img:'เอ็นข้อไก่-sp.webp',size:'1 กก.',priceToon:'0'},
        {name:'บุกขาว kawa',priceAgent: '45 บาท/กก.',price: '49 บาท/กก.',img:'บุกขาว-kawa.webp',size:'1 กก.',priceToon:'0'},
        {name:'ยำสาหร่าย อินาริ',priceAgent: '98 บาท/กก.',price: '105 บาท/กก.',img:'ยำสาหร่าย-อินาริ.webp',size:'1 กก.',priceToon:'0'},
        {name:'เฟรนฟราย 7 มิล วีนัส',priceAgent: '80 บาท/กก.',price: '86 บาท/กก.',img:'เฟรนฟราย-7-มิล-วีนัส.webp',size:'1 กก.',priceToon:'0'},
        {name:'ปูอัดนามิ (ซองสวย) ทวีวงษ์',priceAgent: '28 บาท/แพ็ค',price: '29 บาท/แพ็ค',img:'ปูอัดนามิ-ซองสวย-ทวีวงษ์.webp',size:'250 กรัม',priceToon:'0'},
        {name:'ก้ามปูซูชิ ทวีวงษ์',priceAgent: '62 บาท/แพ็ค',price: '63 บาท/แพ็ค',img:'ก้ามปูซูชิ-ทวีวงษ์.webp',size:'500 กรัม',priceToon:'0'},
        {name:'หมึกฮอกไกโด ทวีวงษ์',priceAgent: '69 บาท/แพ็ค',price: '73 บาท/แพ็ค',img:'หมึกฮอกไกโด-ทวีวงษ์.webp',size:'500 กรัม',priceToon:'0'},
        {name:'เต้าหู้ปลานามิ ทวีวงษ์',priceAgent: '27 บาท/แพ็ค',price: '28 บาท/แพ็ค',img:'เต้าหู้ปลานามิ-ทวีวงษ์.webp',size:'200 กรัม',priceToon:'0'},
        {name:'หยดน้ำไส้เห็ดหอม ทวีวงษ์',priceAgent: '79 บาท/แพ็ค',price: '81 บาท/แพ็ค',img:'หยดน้ำไส้เห็ดหอม-ทวีวงษ์.webp',size:'500 กรัม',priceToon:'0'},
        {name:'เปาทูโทนไข่กุ้ง ทวีวงษ์',priceAgent: '76 บาท/แพ็ค',price: '78 บาท/แพ็ค',img:'เปาทูโทนไข่กุ้ง-ทวีวงษ์.webp',size:'500 กรัม',priceToon:'0'},
        {name:'ก้ามปูหิมะ ทวีวงษ์',priceAgent: '32 บาท/แพ็ค',price: '33 บาท/แพ็ค',img:'ก้ามปูหิมะ-ทวีวงษ์.webp',size:'200 กรัม',priceToon:'0'},
        {name:'ก้ามล๊อบสเตอร์ ทวีวงษ์',priceAgent: '65 บาท/แพ็ค',price: '67 บาท/แพ็ค',img:'ก้ามล๊อบสเตอร์-ทวีวงษ์.webp',size:'500 กรัม',priceToon:'0'},
        {name:'กุ้งหิมะ ทวีวงษ์',priceAgent: '57 บาท/แพ็ค',price: '58 บาท/แพ็ค',img:'กุ้งหิมะ-ทวีวงษ์.webp',size:'500 กรัม',priceToon:'0'},
        {name:'ก้ามโอเชี่ยน ทวีวงษ์',priceAgent: '58 บาท/แพ็ค',price: '59 บาท/แพ็ค',img:'ก้ามโอเชี่ยน-ทวีวงษ์.webp',size:'500 กรัม',priceToon:'0'},
        {name:'คอมโบฟิชบัน ทวีวงษ์',priceAgent: '73 บาท/แพ็ค',price: '75 บาท/แพ็ค',img:'คอมโบฟิชบัน-ทวีวงษ์.webp',size:'500 กรัม',priceToon:'0'},
        {name:'ปูซูชิ ทวีวงษ์',priceAgent: '40 บาท/แพ็ค',price: '41 บาท/แพ็ค',img:'ปูซูชิ-ทวีวงษ์.webp',size:'250 กรัม',priceToon:'0'},
        {name:'คิดดี้แครบ ทวีวงษ์',priceAgent: '60 บาท/แพ็ค',price: '62 บาท/แพ็ค',img:'คิดดี้แครบ-ทวีวงษ์.webp',size:'500 กรัม',priceToon:'0'},
        {name:'คานิมากิ ทวีวงษ์',priceAgent: '56 บาท/แพ็ค',price: '58 บาท/แพ็ค',img:'คานิมากิ-ทวีวงษ์.webp',size:'500 กรัม',priceToon:'0'},
        {name:'ซาลาเปาปลาไส้ข้าวโพดครีมชีส ทวีวงษ์',priceAgent: '67 บาท/แพ็ค',price: '69 บาท/แพ็ค',img:'ซาลาเปาปลาไส้ข้าวโพดครีมชีส-ทวีวงษ์.webp',size:'500 กรัม',priceToon:'0'},
        {name:'ซาลาเปาปลาไส้ไข่เค็ม ทวีวงษ์',priceAgent: '72 บาท/แพ็ค',price: '74 บาท/แพ็ค',img:'ซาลาเปาปลาไส้ไข่เค็ม-ทวีวงษ์.webp',size:'500 กรัม',priceToon:'0'},
        {name:'ดอกไม้ทะเล ทวีวงษ์',priceAgent: '60 บาท/แพ็ค',price: '62 บาท/แพ็ค',img:'ดอกไม้ทะเล-ทวีวงษ์.webp',size:'500 กรัม',priceToon:'0'},
        {name:'ดาวยิ้ม ทวีวงษ์',priceAgent: '55 บาท/แพ็ค',price: '57 บาท/แพ็ค',img:'ดาวยิ้ม-ทวีวงษ์.webp',size:'500 กรัม',priceToon:'0'},
        {name:'ตัวปลา ทวีวงษ์',priceAgent: '57 บาท/แพ็ค',price: '59 บาท/แพ็ค',img:'ตัวปลา-ทวีวงษ์.webp',size:'500 กรัม',priceToon:'0'},
        {name:'ปลาแพนด้า ทวีวงษ์',priceAgent: '56 บาท/แพ็ค',price: '58 บาท/แพ็ค',img:'ปลาแพนด้า-ทวีวงษ์.webp',size:'500 กรัม',priceToon:'0'},
        {name:'ปูปาร์ตี้ ทวีวงษ์',priceAgent: '44 บาท/แพ็ค',price: '45 บาท/แพ็ค',img:'ปูปาร์ตี้-ทวีวงษ์.webp',size:'500 กรัม',priceToon:'0'},
        {name:'ฟรุ๊ตตี้ฟิชเค้ก ทวีวงษ์',priceAgent: '61 บาท/แพ็ค',price: '63 บาท/แพ็ค',img:'ฟรุ๊ตตี้ฟิชเค้ก-ทวีวงษ์.webp',size:'500 กรัม',priceToon:'0'},
        {name:'แซนวิชเต้าหู้ ทวีวงษ์',priceAgent: '64 บาท/แพ็ค',price: '66 บาท/แพ็ค',img:'แซนวิชเต้าหู้-ทวีวงษ์.webp',size:'500 กรัม',priceToon:'0'},
        {name:'โดนัทกุ้ง pn foods',priceAgent: '80 บาท/แพ็ค',price: '83 บาท/แพ็ค',img:'โดนัทกุ้ง-pn-foods.webp',size:'12 ชิ้น',priceToon:'0'},
        {name:'โดนัทกุ้งชีส pn foods',priceAgent: '85 บาท/แพ็ค',price: '88 บาท/แพ็ค',img:'โดนัทกุ้งชีส-pn-foods.webp',size:'12 ชิ้น',priceToon:'0'},
        {name:'กุ้งตอปิโด เเฮปปี้',priceAgent: '54 บาท/แพ็ค',price: '56 บาท/แพ็ค',img:'กุ้งตอปิโด-เเฮปปี้.webp',size:'300 กรัม',priceToon:'0'},
        {name:'ชีสสติ๊ก jaja',priceAgent: '52 บาท/แพ็ค',price: '54 บาท/แพ็ค',img:'ชีสสติ๊ก-jaja.webp',size:'10 แท่ง',priceToon:'0'},
        {name:'ชีสบอล อินดี้',priceAgent: '38 บาท/แพ็ค',price: '39 บาท/แพ็ค',img:'ชีสบอล-อินดี้.webp',size:'8 ลูก',priceToon:'0'},
        {name:'ฮอตชีส pn foods',priceAgent: '70 บาท/แพ็ค',price: '73 บาท/แพ็ค',img:'ฮอตชีส-pn-foods.webp',size:'8 ไม้',priceToon:'0'},
        {name:'ยออุบลแผ่น ผสมหนังหมู',priceAgent: '36 บาท/แพ็ค',price: '36 บาท/แพ็ค',img:'ยอแผ่น-หนังหมู-เหลือง.webp',size:'500 กรัม',priceToon:'0'},
        {name:'ยออุบลแผ่น พริกไทยดำ',priceAgent: '36 บาท/แพ็ค',price: '36 บาท/แพ็ค',img:'ยอแผ่น-พริกไทยดำ-แดง.webp',size:'500 กรัม',priceToon:'0'},
        {name:'ยออุบลแผ่น รสดั้งเดิม',priceAgent: '36 บาท/แพ็ค',price: '36 บาท/แพ็ค',img:'ยอแผ่น-ดั้งเดิม-เขียว.webp',size:'500 กรัม',priceToon:'0'},
        {name:'หอยลายแกะ กระปุก',priceAgent: '25 บาท/แพ็ค',price: '36 บาท/แพ็ค',img:'หอยลายแกะ-กระปุก.webp',size:'200 กรัม',priceToon:'0'},

        // บู๊ฮวดน้ำเงิน ส่ง 75
        
    ]


    realReview = [
        {img : 'real_product_1.jpg',alt : 'real product 1'},
        {img : 'real_product_2.jpg',alt : 'real product 2'},
        {img : 'real_product_3.jpg',alt : 'real product 3'},
        {img : 'real_product_4.jpg',alt : 'real product 4'},
        {img : 'real_product_5.jpg',alt : 'real product 5'},
        {img : 'real_product_6.jpg',alt : 'real product 6'},
        {img : 'real_product_7.jpg',alt : 'real product 7'},
        {img : 'real_product_8.jpg',alt : 'real product 8'},
        {img : 'real_product_9.jpg',alt : 'real product 9'},
        {img : 'real_product_10.jpg',alt : 'real product 10'},
        {img : 'real_product_11.jpg',alt : 'real product 11'},
        {img : 'real_product_12.jpg',alt : 'real product 12'},
        {img : 'real_product_13.jpg',alt : 'real product 13'},
        {img : 'real_product_14.jpg',alt : 'real product 14'},
        {img : 'real_product_15.jpg',alt : 'real product 15'},
        {img : 'real_product_16.jpg',alt : 'real product 16'},
        {img : 'real_product_17.jpg',alt : 'real product 17'},
        {img : 'real_product_18.jpg',alt : 'real product 18'},
        {img : 'real_product_19.jpg',alt : 'real product 19'},
        {img : 'real_product_20.jpg',alt : 'real product 20'}
    ]
    

    render() {
        return (
            <HelmetProvider>
                <Helmet>
                    <title>ลูกชิ้นปลา ขายปลีก-ส่ง ถูกที่สุด บริการอันดับ 1 ส่งสินค้าทุกวัน</title>
                    <link rel="canonical" href="https://xn--12csa3dk0bire5d9cicv6ezn.com/" />
                    <meta name="description" content="เลือกซื้อลูกชิ้นปลา อร่อย ได้คุณภาพ ปลอดภัย ราคาถูก ต้องสั่งที่ ลูกชิ้นปลามหาชัย.com เท่านั้น รับจากโรงงานโดยตรง จัดส่งทุกวัน บริการด้วยใจตลอด 24 ชั่วโมง" />
                </Helmet>
            <div>
                <div className="contentBox1">
                {/*<div className="vdoBox">
                <video src="https://lookchinpramahachai.b-cdn.net/vdo_6621.mp4" type="video/mp4" width="100%" poster="assets/etc/posterz.jpg" controls></video>
                    </div> */}
                    <div className="vdoBox">
                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/7G9RIcns-Bw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                {/*<LazyLoadImage src={`assets/etc/โปรโมชั่นสินค้า.jpg`} alt='โปรโมชั่นสินค้า' className="reviewImg" />*/}
                <h1 style={{color:'white',marginTop:'0px',paddingTop:'15px'}}>ลูกชิ้นปลา ขายส่ง-ปลีก บริการอันดับ 1</h1>
                <p>
                    <strong>ลูกชิ้นปลามหาชัย.com</strong> เราจัดจำหน่าย ลูกชิ้นปลา ขายส่ง ทุกรูปแบบ รับตรงจากโรงงานส่งถึงมือท่าน สินค้าสดใหม่ทุกวัน มั่นใจได้เรื่องคุณภาพ มีครอบคลุมทุกความต้องการสำหรับคนที่ชื่นชอบรับประทานลูกชิ้นปลา รวมถึงพ่อค้าแม่ค้าที่มีแผงขายตามตลาด หรือเป็นเจ้าบ้านร้านอาหารที่ต้องใช้วัตถุดิบลูกชิ้นปลาอยู่เป็นประจำทุกวัน 
                    แต่ยังมองหาแหล่งส่งสินค้าคุณภาพไม่ได้ ราคาไม่โดนใจ ลองแวะมาเลือกชมสินค้าและลองสั่งซื้อกับทางเรา รับประกันความพึงพอใจ <strong>บริการด้วยใจ</strong> เปิดจำหน่ายทั้งปลีกและส่ง เพื่อเสิร์ฟความอร่อยจากโรงงานสู่มือลูกค้า<br/><br/> 
                    ไม่ว่าจะเป็นลูกชิ้นปลาทรงกลม ทรงแหลม ทรงแบน หรือลูกชิ้นปลาบัวลอย (ขนาดลูกเล็กกว่าลูกกลมแบบปกติ) รวมถึงผลิตภัณฑ์อาหารอื่นๆในกลุ่มอาหารทะเล อาทิ ลูกชิ้นกุ้ง ปลาเส้น ทอดมันปลากราย ปูอัดแบรนด์เบนโตะ ปลาหมึกหลอดแช่แข็ง ลูกชิ้นก้ามปู และอีกมากมาย ลูกค้าสามารถดูรายละเอียดสินค้าทั้งหมดพร้อมเรทราคา ปลีก-ส่ง 
                    ได้จากหน้าเว็บไซต์ของเรา <span style={{color:'red'}}>บริการส่งเองถึงที่ในระแวกใกล้เคียง ต.มหาชัย</span> รวมถึงมีบริการส่งสินค้าผ่านบริษัทขนส่งชั้นนำ อัตราค่าส่งคิดตามจริง ขึ้นอยู่กับจำนวนน้ำหนักและระยะทาง
                </p>
                </div>

                <h2>สินค้าขายดีประจำร้าน</h2>

                <div className="productList">
                    <div className="bgHotBox">
                    {
                    this.state.nextLoad||this.props.connMobile===false?
                    this.productHot.map((item,index) => {
                        return (
                            <div className="col col-2 " key={index}>
                                <LazyLoadImage src={`assets/product/${item.img}`} alt={item.name} className="productImg"/>
                                <br/>
                                <strong><span className="titleItem">{item.name}</span></strong>
                            </div>
                        )
                    }):
                    ''
                    }
                    </div>
                </div>

                <h2>รายการสินค้าทั้งหมด</h2>

                <div className="productList">
                    {
                    this.state.nextLoad||this.props.connMobile===false?
                    this.product.map((item,index) => {
                        return (
                            <div className="col col-2 " key={index}>
                                <LazyLoadImage src={`assets/product/${item.img}`} alt={item.name} className="productImg"/>
                                <br/>
                                <strong><span className="titleItem">{item.name}</span></strong>
                                <p>
                                ราคาส่ง : {item.priceAgent}<br/>
                                ขนาดต่อแพ็ค : {item.size}
                                </p>
                            </div>
                        )
                    }):
                    ''
                    }
                </div>
                <div className="contentBox2">
                    <img src='assets/logo/logo.png' alt="logo" className="logoHeader" />
                    <h2>เกี่ยวกับเรา ลูกชิ้นปลามหาชัย.com</h2>
                    <p>เราพยายามคัดสรรลูกชิ้นปลาสดใหม่ จากแหล่งโรงงานที่ได้คุณภาพ กระบวนการผลิตสะอาด ถูกหลัก และมี อย. รองรับ ที่สำคัญต้องรสชาติถูกปากคนส่วนใหญ่ ทางเราอาศัยอยู่ใน <span style={{color:'blue'}}>"มหาชัย"</span> ซึ่งเป็นแหล่งที่มีโรงงานผลิตลูกชิ้นปลาขึ้นชื่อหลายแห่ง รวมถึงอาหารทะเลแปรรูปอีกหลายแบรนด์ มีหลายแบรนด์จำหน่ายอยู่บนห้างสรรพสินค้าชั้นนำของไทย 
                        ซึ่งราคาแตกต่างกันออกไปตามเกรดของสินค้าชนิดนั้นๆ "ลูกชิ้นปลามหาชัย.com" เราอยู่ในแหล่งจำหน่ายผู้ผลิตโดยตรง ไม่ผ่านคนกลาง ทำให้ลูกค้ามั่นใจได้ว่าสินค้าจากทางเรา สดใหม่ คุณภาพดี ปลอดภัย และราคาถูก สามารถซื้อไปเพื่อรับประทานเองหรือจำหน่ายปลีกต่อได้เช่นเดียวกัน มีบริการส่งสินค้าทุกวัน คัดสินค้าเองกับมือ ส่งตรงสู่ลูกค้า 
                        เพื่อคาดหวังความประทับใจจากลูกค้าและกลับมาแวะอุดหนุนเราอีกตลอดไป เลือกมองหาซื้อลูกชิ้นปลาออนไลน์ <Link to="/">ลูกชิ้นปลา</Link> ลูกทอด ลูกต้ม อาหารทะเลแปรรูป ปูอัดแท่ง และอื่นๆ มาเลือกซื้อผ่าน "ลูกชิ้นปลามหาชัย.com"</p>
                    <h2>การจัดส่งสินค้า</h2>
                    {/*
                    <p>ทางร้านมีบริการจัดส่ง 2 รูปแบบ รูปแบบแรก <span style={{color:'red'}}><strong>การนัดรับสินค้า</strong></span> (กรุงเทพฯและปริมณฑล) มีรอบส่งทุกวันอังคาร และวันศุกร์ สั่งสินค้าน้ำหนักรวม <span style={{color:'red'}}><strong>10</strong></span> โลขึ้นไป ส่งฟรีถึงที่ไม่คิดค่าจัดส่งสำหรับ 10 กิโลเมตรแรก เพื่อรองรับพ่อค้าแม่ค้าที่ต้องการซื้อลูกชิ้นปลาไปจำหน่าย และลูกค้าซื้อรับประทาน เราใส่ใจทุกความต้องการของลูกค้าทุกระดับ เพื่อเลือก "ลูกชิ้นปลามหาชัย.com" เป็นคู่ค้าทางธุรกิจของคุณ
                        และส่งอีกรูปแบบคือ <span style={{color:'red'}}><strong>ทางไปรษณีย์</strong></span> จัดส่งสินค้าทุกวัน ค่าจัดส่งคิดตามจริงจากบริษัทขนส่ง ทางร้านรองรับการส่งผ่าน J&T Express, Kerry Express และ Inter Express (ส่งแบบแช่เย็น) ลูกค้าสามารถคละสินค้าได้ ทางร้านคิดจากน้ำหนักรวมของสินค้าเป็นหลัก
                    </p>
                    <div className='boxDiv'>
                    <h3>กรณีนัดรับสินค้า กรุงเทพฯและปริมณฑล (รอบส่งทุกวันอังคาร และวันศุกร์)</h3>
                        <table>
                        <thead>
                            <tr>
                                <th>ระยะทาง</th><th>ค่าจัดส่ง</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>น้อยกว่า 10 กิโลเมตร</td><td>ฟรี</td>
                            </tr>
                            <tr>
                                <td>มากกว่า 10 กิโลเมตร</td><td>กิโลเมตรละ 2 บาท นับจากกิโลเมตรที่ 11</td>
                            </tr>
                            </tbody>
                        </table>
                        <span style={{color:'red'}}>**บางพื้นที่ได้ไม่ได้ให้บริการนัดรับสินค้า รบกวนลูกค้าโปรดสอบถามก่อนนะครับ**</span>
                    

                        <h3>(กรณีผ่านบริษัทขนส่ง)</h3>
                        J&T Express เช็คค่าจัดส่งได้ที่นี่<br/><a href="http://bit.ly/pricesjte" target="_blank" rel="nofollow noreferrer">http://bit.ly/pricesjte</a><br/><br/>
                        Kerry Express เช็คค่าจัดส่งได้ที่นี่<br/><a href="http://bit.ly/priceske" target="_blank" rel="nofollow noreferrer">http://bit.ly/priceske</a><br/><br/>
                        Inter Express (ส่งแบบแช่เย็น) เช็คค่าจัดส่งได้ที่นี่<br/><a href="http://bit.ly/pricesie" target="_blank" rel="nofollow noreferrer">http://bit.ly/pricesie</a><br/><br/>
                        <span style={{color:'red'}}><strong>"ลูกค้าสามารคละสินค้าได้ทั้งหมด คิดเรทส่งจากน้ำหนักรวมของสินค้า เราพร้อมให้บริการ"</strong></span>
                    </div>
                    */}
                    {/*<LazyLoadImage src={`assets/review/review_job_1.jpg`} alt='รีวิวส่งจริง 1' className="reviewImg"/>
                    <LazyLoadImage src={`assets/review/review_job_2.jpg`} alt='รีวิวส่งจริง 2' className="reviewImg"/>*/}
                    <LazyLoadImage src={`assets/review/น้ำจิ้มกระต่ายคู่มีสต๊อก.jpg`} alt='น้ำจิ้มกระต่ายคู่มีสต๊อก' className="reviewImg"/>
                    <LazyLoadImage src={`assets/review/น้ำจิ้มจอมยุทธ์มีสต๊อก.jpg`} alt='น้ำจิ้มจอมยุทธ์มีสต๊อก' className="reviewImg"/>
                    <div className="reviewRealZone">
                    {this.realReview.map((item,index) =>{
                        return <LazyLoadImage src={`assets/review/${item.img}`} alt={item.alt} className="reviewReal" key={index}/>
                    })}
                    </div>
                </div>
                <div className="contactUs">
                    <div className="contentBox3">
                    <h2>ติดต่อสั่งซื้อ หรือสอบถามเพิ่มเติม</h2>
                    <p style={{fontSize:'16px'}}>โทรศัพท์ : 096-946-2289<br/>
                    Line ID : @saridakan (มี @ นำหน้า)<br/>
                    หรือคลิก : <a href="https://lin.ee/Ya2p1qW" rel="nofollow noreferrer" target="_blank">https://lin.ee/Ya2p1qW</a></p>
                    </div>
                <a href="https://lin.ee/Ya2p1qW" rel="nofollow noreferrer" target="_blank"><img src="assets/etc/qr-code.png" alt="qr code" className="qrcodeImg" /></a>
                </div>
            </div>
            </HelmetProvider>
        )
    }
}

export default Homepage
